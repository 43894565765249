import React, { useState } from "react";
import { Link } from "react-router-dom";
import { USERS } from "../../constants";

const Login = ({ history }) => {
   const [loginData, setLoginData] = useState({});
   const handleBlur = (e) => {
      const newLoginData = { ...loginData };
      newLoginData[e.target.name] = e.target.value;
      setLoginData(newLoginData);
   };
   const onFullScreen = () => {
     var elem = document.documentElement;
     if (elem.requestFullscreen) {
       elem.requestFullscreen();
     } else if (elem.webkitRequestFullscreen) {
       elem.webkitRequestFullscreen();
     } else if (elem.msRequestFullscreen) {
       elem.msRequestFullscreen();
     }
   };
   const submitHandler = (e) => {
      let isValidate = false
      console.log(loginData)
      if (loginData && loginData.username && loginData.password) {
         USERS.forEach(j => {
            if (j.username === loginData.username && j.password === loginData.password) {
               localStorage.setItem("token", j.token)
               localStorage.setItem("role", j.role)
               if (j.cod) localStorage.setItem("workerCod", j.cod)
               isValidate = true
               if (isValidate) onFullScreen()
               if (j.role === 2) return history.push("/reviews")
               if (j.role === 3) return history.push("/order-list")
               if (j.role === 4 || j.role === 5) return history.push("/orders")
               if (j.role === 6) return history.push("/delivery")
               if (j.role === 7) return history.push("/user-open")
               history.push("/dashboard-admin");
            } 
         })
      }
      if (!isValidate) alert("Usuario inválido")
      e.preventDefault();
   };

   return (
      <div className="authincation h-100 p-meddle">
         <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center">
               <div className="col-md-6">
                  <div className="authincation-content">
                     <div className="row no-gutters">
                        <div className="col-xl-12">
                           <div className="auth-form">
                              <h4 className="text-center mb-4">
                                 Bienvenido
                              </h4>
                              <form
                                 action=""
                                 onSubmit={(e) => submitHandler(e)}
                              >
                                 <div className="form-group">
                                    <label className="mb-1">
                                       <strong>Usuario</strong>
                                    </label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="Ingresar usuario"
                                       name="username"
                                       onChange={handleBlur}
                                    />
                                 </div>
                                 <div className="form-group">
                                    <label className="mb-1">
                                       <strong>Password</strong>
                                    </label>
                                    <input
                                       type="password"
                                       className="form-control"
                                       name="password"
                                       onChange={handleBlur}
                                    />
                                 </div>
                                 {/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                    <div className="form-group">
                                       <div className="custom-control custom-checkbox ml-1">
                                          <input
                                             type="checkbox"
                                             className="custom-control-input"
                                             id="basic_checkbox_1"
                                          />
                                          <label
                                             className="custom-control-label"
                                             htmlFor="basic_checkbox_1"
                                          >
                                             Remember my preference
                                          </label>
                                       </div>
                                    </div>
                                    <div className="form-group">
                                       <Link to="/page-forgot-password">
                                          Forgot Password?
                                       </Link>
                                    </div>
                                 </div> */}
                                 <div className="text-center">
                                    <input
                                       type="submit"
                                       value="INGRESAR"
                                       className="btn btn-primary btn-block"
                                    />
                                 </div>
                              </form>
                              {/* <div className="new-account mt-3">
                                 <p>
                                    Don't have an account?{" "}
                                    <Link
                                       className="text-primary"
                                       to="/page-register"
                                    >
                                       INGRESAR
                                    </Link>
                                 </p>
                              </div> */}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Login;
