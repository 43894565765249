const firebaseConfig = {
  apiKey: "AIzaSyAdJz6enP9kIzEGUQRkfqen-F8bAkbLHfI",
  authDomain: "lineretail-restaurant.firebaseapp.com",
  databaseURL: "https://lineretail-restaurant-default-rtdb.firebaseio.com",
  projectId: "lineretail-restaurant",
  storageBucket: "lineretail-restaurant.appspot.com",
  messagingSenderId: "160359230369",
  appId: "1:160359230369:web:5e5022becf9118e2a479e0"
};

export default firebaseConfig;