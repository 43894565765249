export const ALLMENU = [
  {
    id: 1,
    name: "Tequeños anticucheros",
    price: 12,
    ingredients: "",
    desc: "6 tequeños de anticucho con guacamole",
    generalCategory: 1,
    generalSubcategory: 1,
    imgUrl: "https://lineretail.pe/images/rey-sabor/tequeños-anticucheros.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 2,
    name: "Chorizo parrillero",
    price: 17,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 1,
    imgUrl: "https://lineretail.pe/images/rey-sabor/chorizo-parrillero.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 3,
    name: "Anticucho parrillero",
    price: 21,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 1,
    imgUrl: "https://lineretail.pe/images/rey-sabor/anticucho-parrillero.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 4,
    name: "Mollejitas a la parrilla",
    price: 20,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 1,
    imgUrl: "https://lineretail.pe/images/rey-sabor/mollejitas-parrilla.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 5,
    name: "Salchipapa especial",
    price: 17,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 1,
    imgUrl: "https://lineretail.pe/images/rey-sabor/salchi-especial.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 6,
    name: "Salchipapa del Rey",
    price: 14,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 2,
    imgUrl: "https://lineretail.pe/images/rey-sabor/salchipapa-rey.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 7,
    name: "Chuleta parrillera",
    price: 22,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 2,
    imgUrl: "https://lineretail.pe/images/rey-sabor/chuleta-parrillera.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 8,
    name: "Bistec a la parrilla",
    price: 25,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 2,
    imgUrl: "https://lineretail.pe/images/rey-sabor/bistec-parrilla.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 9,
    name: "Filete de pierna a la parilla",
    price: 19,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 2,
    imgUrl: "https://lineretail.pe/images/rey-sabor/filete-pierna.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 10,
    name: "Pechuga a la parrilla",
    price: 22,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 2,
    imgUrl: "https://lineretail.pe/images/rey-sabor/pechuga-parrilla.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 11,
    name: "Churrasco a la parrilla",
    price: 23,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/churrasco-parrilla.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 12,
    name: "Parrilla familiar",
    price: 105,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/parrilla-familiar.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 13,
    name: "Parrilla lovers",
    price: 67,
    ingredients: "",
    desc: "",
    generalCategory: 1,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/parrilla-lovers.jpeg",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  // COMBOS
  {
    id: 14,
    name: "Combo 1",
    price: 123,
    ingredients: "",
    chickenQuantity: 1.5,
    drinkIdIncluded: 27,
    desc: "2 POLLOS + 2 INKA KOLA 1.5L O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/combo1.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 15,
    name: "Combo 2",
    price: 80,
    ingredients: "",
    chickenQuantity: 1.5,
    desc: "1 POLLO + 1/2 POLLO + 1 INKA KOLA 1.5L O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/combo2.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 16,
    name: "Combo 3",
    price: 77,
    ingredients: "",
    chickenQuantity: 1.25,
    drinkIdIncluded: 27,
    desc: "1 POLLO + 1/2 POLLO + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/combo3.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 17,
    name: "Combo 4",
    price: 77,
    ingredients: "",
    chickenQuantity: 1.25,
    desc: "1 POLLO + 1/4 POLLO + 1 INKA KOLA 1.5L O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/combo4.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 18,
    name: "Combo 5",
    price: 75,
    ingredients: "",
    chickenQuantity: 1,
    drinkIdIncluded: 28,
    desc: "1 POLLO + 1/4 POLLO + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/combo5.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 19,
    name: "Combo 6",
    price: 65,
    ingredients: "",
    chickenQuantity: 1,
    desc: "1 POLLO + 1 INKA KOLA 1.5L O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/combo6.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 20,
    name: "Combo 7",
    price: 58,
    ingredients: "",
    chickenQuantity: 0.5,
    drinkIdIncluded: 27,
    desc: "1 POLLO + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/combo7.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 21,
    name: "Combo 8",
    price: 41,
    ingredients: "",
    chickenQuantity: 0.5,
    drinkIdIncluded: 28,
    desc: "1/2 POLLO + 1 INKA KOLA 1.5L O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/combo8.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 22,
    name: "Combo 9",
    price: 40,
    ingredients: "",
    chickenQuantity: 0.5,
    drinkIdIncluded: 29,
    desc: "1/2 POLLO + 1 INKA KOLA 1L O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/combo9.png",
    kitchenAuthorized: ["cocna02"]
  },
  {
    id: 23,
    name: "Combo 10",
    price: 38,
    ingredients: "",
    chickenQuantity: 0.5,
    drinkIdIncluded: 30,
    desc: "1/2 POLLO + 1 INKA KOLA O GORDITA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/combo10.png",
    kitchenAuthorized: ["cocna02"]
  },
  {
    id: 24,
    name: "Combo 11",
    price: 34,
    ingredients: "",
    chickenQuantity: 0.5,
    desc: "1/2 POLLO + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/combo11.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 25,
    name: "Combo 12",
    price: 19,
    ingredients: "",
    chickenQuantity: 0.25,
    drinkIdIncluded: 30,
    desc: "1/2 POLLO + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 6,
    imgUrl: "https://lineretail.pe/images/rey-sabor/combo12.png",
    kitchenAuthorized: ["cocna02"]
  },
  {
    id: 26,
    name: "Combo 13",
    price: 20,
    ingredients: "",
    chickenQuantity: 0.25,
    drinkIdIncluded: 31,
    desc: "1/4 POLLO + GASEOSA MEDIANA O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 7,
    imgUrl: "https://lineretail.pe/images/rey-sabor/combo13.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 48,
    name: "Combo 14",
    price: 23,
    ingredients: "",
    chickenQuantity: 0.25,
    drinkIdIncluded: 31,
    desc: "1/4 POLLO + GASEOSA MEDIANA O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 7,
    imgUrl: "https://lineretail.pe/images/rey-sabor/combo14.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 49,
    name: "Combo 15",
    price: 20,
    ingredients: "",
    chickenQuantity: 0.25,
    drinkIdIncluded: 31,
    desc: "1/4 POLLO + GASEOSA MEDIANA O CHICHA MORADA + PAPAS FRITAS + ENSALADA FRITAS + CREMAS DE LA CASA",
    generalCategory: 2,
    generalSubcategory: 7,
    imgUrl: "https://lineretail.pe/images/rey-sabor/combo15.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  // BEBIDAS
  {
    id: 27,
    name: "Gaseosa 1.5 Litros",
    price: 10,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 7,
    imgUrl: "https://lineretail.pe/images/rey-sabor/inka-1p5.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  }, 
  {
    id: 28,
    name: "Gaseosa 1 Litro",
    price: 3,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 7,
    imgUrl: "https://lineretail.pe/images/rey-sabor/inka-1.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 29,
    name: "Gordita",
    price: 5,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/gordita.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 30,
    name: "Gaseosa 500ml",
    price: 5,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/500vidrio.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 31,
    name: "Gaseosa 500ml descartable",
    price: 5,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/500desc.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 32,
    name: "Agua",
    price: 3.5,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/agua500.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 33,
    name: "Chicha morada 1L",
    price: 8,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/chicha.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 34,
    name: "Chicha morada 1/2 L",
    price: 4,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/chicha-med.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 35,
    name: "Cusqueña",
    price: 6,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/cusqueña.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 36,
    name: "Pilsen",
    price: 6,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/pilsen.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 37,
    name: "Cristal",
    price: 5,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/cristal.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 38,
    name: "Corona",
    price: 6,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/corona.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 39,
    name: "Vino",
    price: 22,
    ingredients: "",
    desc: "",
    generalCategory: 3,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/vino.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  // CREMAS
  {
    id: 40,
    name: "Mayonesa - 4oz",
    price: 4,
    ingredients: "",
    desc: "",
    generalCategory: 4,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/4oz.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 41,
    name: "Ají, ketchup, etc - 6oz",
    price: 7,
    ingredients: "",
    desc: "",
    generalCategory: 4,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/8oz.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 42,
    name: "Chimichurri - 4oz",
    price: 5,
    ingredients: "",
    desc: "",
    generalCategory: 4,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/chimi.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  //GUARNICIONES
  {
    id: 43,
    name: "Porción de papas fritas",
    price: 10,
    ingredients: "",
    desc: "",
    generalCategory: 5,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/papasfrit.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  },
  {
    id: 44,
    name: "Ensalada",
    price: 5,
    ingredients: "",
    desc: "",
    generalCategory: 5,
    generalSubcategory: 3,
    imgUrl: "https://lineretail.pe/images/rey-sabor/ensalada.png",
    kitchenAuthorized: ["cocna01","cocna02"]
  }
]

export const GENERAL_CATEGORIES = [
  {
    label: "PARRILLAS",
    value: 1
  },
  {
    label: "COMBOS",
    value: 2
  },
  {
    label: "BEBIDAS",
    value: 3
  },
  {
    label: "CREMAS",
    value: 4
  },
  {
    label: "GUARNICIONES",
    value: 5
  },
  {
    label: "INFUSIONES",
    value: 6
  }
]

export const GENERAL_SUBCATEGORIES = [
  {
    label: "CARNES Y PLATOS A LA PARRILLA",
    value: 1
  },
  {
    label: "POLLO",
    value: 2
  },
  {
    label: "SANGUCHES",
    value: 3
  },
  {
    label: "FAVORITOS",
    value: 4
  },
  {
    label: "GUARNICIONES",
    value: 5
  },
  {
    label: "ENDULZATE EL DIA",
    value: 6
  },
  {
    label: "PARA LA SED",
    value: 7
  }
]

export const STATES_ORDER = [
  {
    label: "Borrador",
    value: 1
  },
  {
    label: "Por preparar",
    value: 2
  },
  {
    label: "Pagado",
    value: 3
  },
  {
    label: "Cancelado",
    value: 4
  },
   {
     label: "Delivery Pagado",
     value: 5
   },
   {
     label: "Devuelto",
     value: 6
   },
   {
     label: "Listo para mesa",
     value: 7
   },
   {
    label: "Listo para llevar",
    value: 8
  },
   {
     label: "En camino",
     value: 9
   },
   {
     label: "Entregado",
     value: 10
   }
]

export const STATES_MOTORCYCLES = [
  {
    label: "Disponible",
    value: 1
  },
  {
    label: "Ocupado",
    value: 2
  }
]

export const PAY_TYPES = [
  {
    label: "Efectivo",
    value: 1
  },
  {
    label: "Visa",
    value: 2
  },
  {
    label: "Mastercard",
    value: 3
  },
  {
    label: "Yape",
    value: 4
  },
  {
    label: "Plin",
    value: 5
  }
]

export const USERS = [
  {
    username: "robert.quiroz",
    password: "espinoza1",
    cod: "admn01",
    name:"Robert Quiroz",
    role: 1,
    token: "DASKLFJASIJDSALDJHDSS"
  },
  {
    username: "rolando.canales",
    password: "cruz1",
    cod: "admn02",
    name:"Rolando Canales",
    role: 1,
    token: "DASKLFJASIJDSALDJHDSS"
  },
  // ASISTENTES DE COCINA
    {
    username: "cocina1",
    password: "cocina1",
    cod: "cocna01",
    name:"Cocina Delivery",
    role: 2,
    token: "JKLDSAJNVLKEJBOIRVH"
  },
  {
    username: "cocina2",
    password: "cocina2",
    cod: "cocna02",
    name:"Cocina Restaurante",
    role: 2,
    token: "67843123DJKSADKSASF"
  },
  {
    username: "cocina3",
    password: "cocina3",
    cod: "cocna02",
    name:"Cocina Restaurante",
    role: 2,
    token: "67843123DJKSADKSASF"
  },
  // ASISTENTES DE CAJA LOCALES SURCO
  {
    username: "caja1",
    password: "caja1",
    cod:"cja01",
    name:"Asistente de caja 1",
    role: 3,
    token: "OPKBJIOSDLAJBNIMESHV"
  },
  {
    username: "caja2",
    password: "caja2",
    cod:"cja02",
    name:"Asistente de caja 2",
    role: 3,
    token: "58234HJFASJBNIMESHV"
  },
  // MESEROS LOCAL SURCO
  {
    username: "sala1",
    password: "sala1",
    cod: "agntmsa01",
    name:"Mesero 1",
    role: 4,
    token: "O3451263748KMVNAKDUHDU"
  },
  {
    username: "sala2",
    password: "sala2",
    name:"Mesero 2",
    cod: "agntmsa02",
    role: 4,
    token: "98509243JUITYESADFEF"
  },
  {
    username: "sala3",
    password: "sala3",
    cod: "agntmsa03",
    name:"Mesero 3",
    role: 4,
    token: "OIHUEIUVJLQUFIJSADFEF"
  },
  //AGENTES CALL CENTER
  {
    username: "callcenter1",
    password: "callcenter1",
    cod: "call01",
    name:"Alberto Flores",
    role: 5,
    token: "UDIASOUDHUEE(#3$fDSUTT"
  },
  {
    username: "callcenter2",
    password: "callcenter2",
    cod: "call02",
    name:"Gonzalo Delfín",
    role: 5,
    token: "432khFDSFDSG(#3$fDSUTT"
  },
  {
    username: "callcenter3",
    password: "callcenter3",
    cod: "call03",
    name:"Gabriel Rodriguez",
    role: 5,
    token: "UDI478324952JVKSDLFT"
  },
  // MOTORIZADOS
  {
    username: "moto1",
    password: "moto1",
    cod: "mto01",
    name:"Motorizado 1",
    role: 6,
    token: "UDI478&/#24952JVKSDLFT"
  },
  {
    username: "moto2",
    password: "moto2",
    cod: "mto02",
    name:"Motorizado 2",
    role: 6,
    token: "UDI478324952JVKSDLFT"
  },
  // APERTURA
  {
    username: "apertura1",
    password: "apertura1",
    cod: "apt01",
    name:"Usuario apertura",
    role: 7,
    token: "KÑLJDSANFLKJHKRW732847"
  }
]

export const ALL_WAITRESS = [
  {
    fullNames: "mesero 1",
    code: 1
  },
  {
    fullNames: "mesero 2",
    code: 2
  },
  {
    fullNames: "mesero 3",
    code: 3
  }
]

export const ROLES = [
  {
    label: "Administrador",
    value: 1
  },
  {
    label: "Asistente de cocina",
    value: 2
  },
  {
    label: "Asistente de caja",
    value: 3
  },
  {
    label: "Meseros",
    value: 4
  },
  {
    label: "Call Center",
    value: 5
  },
  {
    label: "Motorizado",
    value: 6
  },
  {
    label: "Apertura",
    value: 7
  }
]

export const REASONS_RETURN_ORDER = [
  {
    reason: "Demora de entrega",
    cod: 1
  },
  {
    reason: "Pedido comida fría",
    cod: 2
  },
  {
    reason: "Calidad de servicio",
    cod: 3
  }
]

export const REASONS_CANCEL_ORDER = [
  {
    reason: "BILLETE FALSO O ROTO",
    cod: 1
  },
  {
    reason: "A SOLICITUD DE CLIENTE",
    cod: 2
  }
]

export const OPEN_DAYS_STATES = [
  {
    label: "APERTURADO",
    value: 1
  },
  {
    label: "CERRADO",
    value: 2
  }
]