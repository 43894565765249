import React from 'react'
import { Link, useHistory } from "react-router-dom";

// images
import image01 from "../../../images/product/1.jpg";
import image02 from "../../../images/tab/1.jpg";
import image03 from "../../../images/tab/2.jpg";
import image04 from "../../../images/tab/3.jpg";
import image05 from "../../../images/tab/4.jpg";

import { Card, Form, InputNumber, Button, Modal } from 'antd';
import { Dropdown } from "react-bootstrap";
import RestomaticOnlineServices from "../../../services/services";
import TimeAgo from 'react-timeago'
import {OPEN_DAYS_STATES, PAY_TYPES, USERS} from "../../../constants";
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
const formatter = buildFormatter(spanishStrings)
const moment = require('moment-timezone');

const InfoChikens = ({ daySelected, allDaysOpeneds, getLastCloseDayChicken, onFinish, onFinishFailed, calculateTotalChikensAddEnters }) => {
  console.log(allDaysOpeneds)
  console.log(daySelected)
  return (
    <div className="row">
    <h3 className="col-12">Información de pollos</h3>
    <h4 className="col-12">Total hasta el momento: {(daySelected && daySelected.chikensInformation && daySelected.chikensInformation.initQuantity && !(daySelected && daySelected.chikensInformation && daySelected.chikensInformation.enters) ?
    daySelected && daySelected.chikensInformation && daySelected.chikensInformation.initQuantity
    :
    (daySelected && daySelected.chikensInformation && daySelected.chikensInformation.enters && daySelected.chikensInformation.initQuantity) && calculateTotalChikensAddEnters(daySelected.chikensInformation.enters, daySelected && daySelected.chikensInformation && daySelected.chikensInformation.initQuantity)
    )} pollos consumidos
    </h4>
    <div className="widget-timeline-icon col-lg-3">
        <ul className="timeline">
           <li>
           <div className="icon bg-primary" />
           <a className="timeline-panel text-muted">
              <h5 className="mb-2">INICIO</h5>
              <p className="fs-15 mb-0 ">
                {(allDaysOpeneds && allDaysOpeneds.length > 1 && !(daySelected && daySelected.chikensInformation && daySelected.chikensInformation.initQuantity)) ? getLastCloseDayChicken(daySelected, false) : daySelected && daySelected.chikensInformation && daySelected.chikensInformation.initQuantity} POLLOS
              {/* {(!((allDaysOpeneds && allDaysOpeneds.length > 1) && getLastCloseDayChicken(daySelected)) && daySelected && daySelected.chikensInformation && daySelected.chikensInformation.initQuantity) || null} POLLOS */}
              </p>
           </a>
           </li>
           {
           // (showDetailOrderSelected && showDetailOrderSelected.deliveryInformation && showDetailOrderSelected.deliveryInformation.state >= 9) &&
           <li>
           <div className="icon bg-primary" />
           <a className="timeline-panel text-muted">
              <h4 className="mb-2">INGRESOS</h4>
              {(daySelected && daySelected.chikensInformation && daySelected.chikensInformation.enters) && daySelected.chikensInformation.enters.map(e => {
                return (
                  <p className="fs-15 mb-0 ">
                    + {e.enterQuantity} POLLOS
                  </p>
                )
              })}
           </a>
           </li>
           }
           {
           // (showDetailOrderSelected && showDetailOrderSelected.deliveryInformation && showDetailOrderSelected.deliveryInformation.state === 10) &&
           <li>
           <div className="icon bg-primary" />
           <a className="timeline-panel text-muted">
              <h4 className="mb-2">CIERRE</h4>
              <p className="fs-15 mb-0 ">
              {(daySelected && daySelected.chikensInformation && daySelected.chikensInformation.closeQuantity) || 0} POLLOS
              </p>
           </a>
           </li>
           }
        </ul>
     </div>
    <Form
       name="basic"
       labelCol={{ span: 10 }}
       wrapperCol={{ span: 16 }}
       initialValues={{ remember: true }}
       onFinish={onFinish}
       onFinishFailed={onFinishFailed}
       autoComplete="off"
    >
       <Form.Item
       label="INICIO"
       name="initQuantity"
       rules={[{ required: true, message: 'Please input your username!' }]}
       >
         {
           (getLastCloseDayChicken(daySelected)) ?
           <InputNumber disabled={true} />
           :
           <InputNumber disabled={daySelected && daySelected.chikensInformation && daySelected.chikensInformation.initQuantity}/>
         }
       
       </Form.Item>

       <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
       <Button type="primary" htmlType="submit" disabled={daySelected.state === 2 || getLastCloseDayChicken(daySelected) || (daySelected && daySelected.chikensInformation && daySelected.chikensInformation.initQuantity)}>
          REGISTRAR
       </Button>
       </Form.Item>
    </Form>
    <Form
       name="basic"
       labelCol={{ span: 11 }}
       wrapperCol={{ span: 16 }}
       initialValues={{ remember: true }}
       onFinish={onFinish}
       onFinishFailed={onFinishFailed}
       autoComplete="off"
    >
       <Form.Item
       label="INGRESO"
       name="enterQuantity"
       rules={[{ required: true, message: 'Please input your username!' }]}
       >
       <InputNumber />
       </Form.Item>

       <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
       <Button type="primary" htmlType="submit" disabled={daySelected.state === 2}>
          REGISTRAR
       </Button>
       </Form.Item>
    </Form>
    <Form
       name="basic"
       labelCol={{ span: 10 }}
       wrapperCol={{ span: 16 }}
       initialValues={{ remember: true }}
       onFinish={onFinish}
       onFinishFailed={onFinishFailed}
       autoComplete="off"
    >
       <Form.Item
       label="CIERRE"
       name="closeQuantity"
       rules={[{ required: true, message: 'Please input your username!' }]}
       >
       <InputNumber disabled={daySelected && daySelected.chikensInformation && daySelected.chikensInformation.closeQuantity}/>
       </Form.Item>

       <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
       <Button type="primary" htmlType="submit" disabled={daySelected.state === 2}>
          REGISTRAR
       </Button>
       </Form.Item>
    </Form>
{/* <Button type="primary" onClick={() => finishDay()} disabled={daySelected.state === 2} danger>
CERRAR DIA
</Button> */}
 </div>

  )
}

export default InfoChikens;
