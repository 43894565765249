import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

// images
import image01 from "../../../images/product/1.jpg";
import image02 from "../../../images/tab/1.jpg";
import image03 from "../../../images/tab/2.jpg";
import image04 from "../../../images/tab/3.jpg";
import image05 from "../../../images/tab/4.jpg";
import {OPEN_DAYS_STATES, PAY_TYPES, USERS} from "../../../constants";
import TimeAgo from 'react-timeago'
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { Card, Form, InputNumber, Button, Modal } from 'antd';
import RestomaticOnlineServices from "../../../services/services";
const formatter = buildFormatter(spanishStrings)
const moment = require('moment-timezone');

const EcomProductDetails = ({reference, getAllOpenedsDays, getAllDishes}) => {
   const history = useHistory();
   const [optionSelected, setOptionSelected] = useState(null)
   const [initDay, setInitDay] = useState(false)
   const [allDaysOpeneds, setAllDaysOpeneds] = useState([])
   const [showModalDaysClose, setShowModalDaysClose] = useState(false)
   const [daySelected, setDaySelected] = useState(null)
   const [dishesBD, setDishesBD] = useState([])
   const [isUpdating, setIsUpdating] = useState(false)
   const gridStyle = {
      cursor: "pointer",
      textAlign: 'center',
    };
    const showModalOpenedDays = () => {
      setShowModalDaysClose(true)
    }
    const selectOption = (option) => {
      console.log(option)
      const auxDaySelected = {...daySelected}
      if (option && auxDaySelected && auxDaySelected.keyBD) {
         // const service = new RestomaticOnlineServices(reference)
         // if (option === 1) {
         //    auxDaySelected.chikensInformation = {}
         //    auxDaySelected.chikensInformation.initQuantity = 0
         //    auxDaySelected.chikensInformation.enterQuantity = 0
         //    auxDaySelected.chikensInformation.closeQuantity = 0
         //    auxDaySelected.chikensInformation.dateInit = ""
         //    auxDaySelected.chikensInformation.dateEnter = ""
         //    auxDaySelected.chikensInformation.dateClose = ""
            
         //    service.updateOpenDay(auxDaySelected.keyBD, auxDaySelected).then(res => {
         //       getAllopenedDaysFirebase(auxDaySelected)
         //    })
         // }
         setOptionSelected(option)
      }
    }
    const onFinish = (values) => {
      if (values && daySelected && daySelected.keyBD) {
         const auxDaySelected = {...daySelected}
         auxDaySelected.chikensInformation = (daySelected && daySelected.chikensInformation) ? {...daySelected.chikensInformation} : {}
         if (values.initQuantity) {
            console.log('Success:', values);
            auxDaySelected.chikensInformation = {...auxDaySelected.chikensInformation}
            auxDaySelected.chikensInformation.initQuantity = values.initQuantity || null
            auxDaySelected.chikensInformation.dateInit = moment.tz(new Date(), "America/Lima").format()
         }
         if (values.enterQuantity) {
            console.log('Success:', values);
            auxDaySelected.chikensInformation = {...auxDaySelected.chikensInformation}
            auxDaySelected.chikensInformation.enterQuantity = values.enterQuantity || null
            auxDaySelected.chikensInformation.dateEnter = moment.tz(new Date(), "America/Lima").format()
         }
         if (values.closeQuantity) {
            console.log('Success:', values);
            auxDaySelected.chikensInformation = {...auxDaySelected.chikensInformation}
            auxDaySelected.chikensInformation.closeQuantity = values.closeQuantity || null
            auxDaySelected.chikensInformation.dateClose = moment.tz(new Date(), "America/Lima").format()
         }
         const service = new RestomaticOnlineServices(reference)

         service.updateOpenDay(auxDaySelected.keyBD, auxDaySelected).then(res => {
            getAllopenedDaysFirebase(auxDaySelected)
            setDaySelected(auxDaySelected)
         })
      }
    };
    const getAllDishesFirebase = () => {
      let allIds = []
      let allDishesBDAux = []
      getAllDishes.on("value", (snapshot) => {
           if (snapshot.val() !== null) {
            snapshot.forEach(e => {
              allIds.push(e.key)
            })
             allDishesBDAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
             allDishesBDAux = Object.values(allDishesBDAux)
             allDishesBDAux = allDishesBDAux.map((e, index) => {
                console.log(allIds)
               if (allIds.length) {
               allIds.forEach(k => {
                 if (!e.keyBD) e.keyBD = `DISHID${allIds[index]}`
               })
               }
               return e
            })
            if (allDishesBDAux.length) {
              setDishesBD(allDishesBDAux)
             console.log(allDishesBDAux, "allDishesBDAux")
            }
           }
           return;
         }, (error) => {
           console.log("ERROR: " + error.code);
         });
      }
      if (dishesBD.length === 0) getAllDishesFirebase()
    console.log(daySelected)
    const getAllopenedDaysFirebase = (objAux) => {
      let allIds = []
      let daysOpenedsAux = []
      getAllOpenedsDays.on("value", (snapshot) => {
           if (snapshot.val() !== null) {
            snapshot.forEach(e => {
              allIds.push(e.key)
            })
             daysOpenedsAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
             daysOpenedsAux = Object.values(daysOpenedsAux)
             daysOpenedsAux = daysOpenedsAux.map((e, index) => {
                console.log(allIds)
               if (allIds.length) {
               allIds.forEach(k => {
                 if (e && !e.keyBD) e.keyBD = `ORDERID${allIds[index]}`
               })
               }
               return e
            })
            setAllDaysOpeneds(daysOpenedsAux)
            console.log(daysOpenedsAux)
            console.log(daySelected)
            let result = {}
            if (daysOpenedsAux && objAux && !objAux.keyBD) {
               console.log(daysOpenedsAux)
               console.log(daySelected)
               daysOpenedsAux.forEach(j => {
                  const aux = { ...objAux}
                  if (aux && aux.id && j.id === aux.id && !aux.keyBD) {                     
                     aux.keyBD = ""
                     aux.keyBD = j.keyBD
                     setDaySelected(aux)
                  } else setDaySelected(objAux)
               })
            }
             console.log(daysOpenedsAux, "daysOpenedsAux")
           }
           return;
         }, (error) => {
           console.log("ERROR: " + error.code);
         });
      }
      console.log(daySelected)
      if (allDaysOpeneds.length === 0) getAllopenedDaysFirebase()
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };
    const initDayFn = (obj2) => {
      setIsUpdating(true)
       const obj = { ...obj2,
         openedDay:  moment.tz(new Date(), "America/Lima").format(),
         id: Math.floor(Math.random() * (1000000 - 10000)) + 100,
         state: 1
       }
       console.log(allDaysOpeneds)
       if (allDaysOpeneds && allDaysOpeneds.length > 1) {
         const auxResult = getLastCloseDayChicken(obj, false)
         const auxResultDrink = getLastCloseDayDrinks(obj, false)
         console.log(auxResult)
         console.log(auxResultDrink)
         obj.chikensInformation = {
            initQuantity: (auxResult && auxResult.closeQuantity) && auxResult.closeQuantity,
            dateInit: moment.tz(new Date(), "America/Lima").format()
         }
         obj.drinksInformation = {
            initQuantity: (auxResultDrink && auxResultDrink.closeQuantity && auxResultDrink.closeQuantity) || 0,
            dateInit: moment.tz(new Date(), "America/Lima").format()
         }
       }
       console.log(obj)
       setDaySelected(obj)
      const service = new RestomaticOnlineServices(reference)
      service.saveOpenOfDay(obj).then(res => {
         setInitDay(true)
         console.log(res)
         if (obj) getAllopenedDaysFirebase(obj)
       })
       
       let count = []
       setTimeout(() => {
         if (dishesBD && dishesBD.length) {
           dishesBD.forEach(k => {
              k.soldsUnids = 0
                 updDi(k, obj, count)  
         })       
        }
       }, 1200)

      if (count && count.length === 44)
         setIsUpdating(false)
    }
    const updDi = async function(k, obj, count) {
      const service = new RestomaticOnlineServices(reference)
      service.updateDishUsed(k.keyBD, k).then(res => {
         console.log(res)
         console.log(isUpdating)
         count.push(k)
      }, err => {
         console.log(err)
      });
      history.push(`/user-open/detail/${obj.id}`)
    }
    console.log(isUpdating)
    const updateAllDishes = () => {
       
    }
    const goBack = () => {
      setInitDay(false)
    }
    const showModalForDeliveryChatBox = (option) => {
      console.log(option)
      // if (option === "add") setShowModalForDelivery(true)
      
    }
    const getStateByCode = (state) => {
      let result = ""
      if (state) {
         OPEN_DAYS_STATES.forEach(e => {
          if (e.value === state) result = e.label
        })
      }
      return result;   
    }
    const filterIsBetweenToday = (date) => {
      let result = false;
      const todayDate = moment.tz(new Date(), "America/Lima").format()
      // console.log(todayDate)
      let initDay = todayDate.split("T")
      let finalDay = todayDate.split("T")
      initDay = initDay[0]+"T00:00:00-05:00"
      finalDay = finalDay[0]+"T23:59:59-05:00"
      initDay = Date.parse(initDay)
      finalDay = Date.parse(finalDay)
      date = Date.parse(date)
      console.log(initDay)
      console.log(finalDay)
      console.log(date)
      // if (moment.tz(Date.parse(date).isAfter(Date.parse(initDay)))) result = true
      if (initDay <= date && date <= finalDay) result = true
      return result
    }
    let todayIs = ""
    todayIs = moment.tz(new Date(), "America/Lima").format()
    todayIs = todayIs.split("T")
    todayIs = todayIs[0]
    let existDayOpened = false
    if (allDaysOpeneds && todayIs) {
      allDaysOpeneds.forEach(e => {
         const dateAux = new Date(e.openedDay)
         console.log(typeof dateAux)
          if (e && e.openedDay && e.openedDay && todayIs && filterIsBetweenToday(dateAux)) {
             existDayOpened = true
          }
      })
    }
    console.log(existDayOpened)
    const getLastCloseDayChicken = (day, withDayOpened) => {
       console.log(day)
      let result = null;
      let resultQ = null
      if (day && allDaysOpeneds) {
         console.log(allDaysOpeneds)
         console.log(withDayOpened)
         console.log(day)
         if (!withDayOpened) result = allDaysOpeneds.reverse()[allDaysOpeneds.length-1]
         if (withDayOpened) result = allDaysOpeneds.reverse()[allDaysOpeneds.length-2]
       
      }
      if (result && result.chikensInformation && result.chikensInformation.closeQuantity) resultQ = result.chikensInformation;
      return resultQ;
    }
    const getLastCloseDayDrinks = (day, withDayOpened) => {
      console.log(day)
     let result = null;
     let resultQ = null
     if (day && allDaysOpeneds) {
        console.log(allDaysOpeneds)
        console.log(withDayOpened)
        console.log(day)
        if (!withDayOpened) result = allDaysOpeneds.reverse()[allDaysOpeneds.length-1]
        if (withDayOpened) result = allDaysOpeneds.reverse()[allDaysOpeneds.length-2]
      
     }
     if (result && result.drinksInformation && result.drinksInformation.closeQuantity) resultQ = result.drinksInformation;
     return resultQ;
   }
   return (
      <>
         <div className="row page-titles mx-0">
            <div className="col-sm-6 p-md-0">
               <div className="welcome-text">
                  <h4>HOLA!,<br/> CONFIGURA LA APERTURA DE HOY</h4>
                  <h5>FECHA DE HOY: {todayIs}</h5>
                  {existDayOpened && <h5 className="text-red">YA HAS APERTURADO EL DIA DE HOY, INGRESA A "VER CIERRES"</h5>}
                  <p className="mb-0">Your business dashboard template</p>
               </div>
            </div>
            <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
               <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <a>APERTURA</a>
                  </li>
                  <li className="breadcrumb-item active">
                     <a>INICIO DE DIA</a>
                  </li>
               </ol>
            </div>
         </div>
         <div className="row">
            {
               initDay &&
            <Button type="primary" onClick={() => goBack()} danger>
                  ATRAS
               </Button>
            }
                 <button className="btn btn-primary" onClick={() => showModalOpenedDays()}>
                  VER CIERRES
                </button>
            <br />
            <br />
            {
               (!initDay) ?
               <Button type="primary" style={{margin: "5px"}} disabled={(allDaysOpeneds && allDaysOpeneds.length) ? existDayOpened : false} onClick={() => initDayFn({openedDay: "", id: 0})} danger>
               {isUpdating ? "INICIANDO..." : "INICIAR DIA"}
           </Button>
           :
           <div className="col-lg-12">
              <div className="card">
                 <div className="card-body">
                    <div className="site-card-wrapper">
                    {
                       !optionSelected &&
                       <Card title="INVENTARIO" className="card-warehouse">
                          <Card.Grid className="col-sm-6 col-md-3 col-lg-3" onClick={() => selectOption(1)} style={gridStyle}>POLLOS</Card.Grid>
                          <Card.Grid className="col-sm-6 col-md-3 col-lg-3" onClick={() => selectOption(2)} style={gridStyle}>PLATOS</Card.Grid>
                          <Card.Grid className="col-sm-6 col-md-3 col-lg-3" onClick={() => selectOption(3)} style={gridStyle}>CREMAS</Card.Grid>
                          <Card.Grid className="col-sm-6 col-md-3 col-lg-3" onClick={() => selectOption(4)} style={gridStyle}>BEBIDAS</Card.Grid>
                       </Card>
                    }
                    {
                       optionSelected &&
                       <div className="row">
                          <h3 className="col-12">Información de pollos</h3>
                          <div className="widget-timeline-icon col-lg-3">
                              <ul className="timeline">
                                 <li>
                                 <div className="icon bg-primary" />
                                 <a className="timeline-panel text-muted">
                                    <h5 className="mb-2">INICIO</h5>
                                    <p className="fs-15 mb-0 ">
                                    {/* {(showDetailOrderSelected && showDetailOrderSelected.dateRegistered)} */}
                                    </p>
                                 </a>
                                 </li>
                                 {
                                 // (showDetailOrderSelected && showDetailOrderSelected.deliveryInformation && showDetailOrderSelected.deliveryInformation.state >= 9) &&
                                 <li>
                                 <div className="icon bg-primary" />
                                 <a className="timeline-panel text-muted">
                                    <h4 className="mb-2">ACTUAL</h4>
                                    <p className="fs-15 mb-0 ">
                                       {/* {showDetailOrderSelected.deliveryInformation.shippingDate} */}
                                    </p>
                                 </a>
                                 </li>
                                 }
                                 {
                                 // (showDetailOrderSelected && showDetailOrderSelected.deliveryInformation && showDetailOrderSelected.deliveryInformation.state === 10) &&
                                 <li>
                                 <div className="icon bg-primary" />
                                 <a className="timeline-panel text-muted">
                                    <h4 className="mb-2">CIERRE</h4>
                                    <p className="fs-15 mb-0 ">
                                       {/* {showDetailOrderSelected.deliveryInformation.arrivalDate} */}
                                    </p>
                                 </a>
                                 </li>
                                 }
                              </ul>
                           </div>
                          <Form
                             name="basic"
                             labelCol={{ span: 10 }}
                             wrapperCol={{ span: 16 }}
                             initialValues={{ remember: true }}
                             onFinish={onFinish}
                             onFinishFailed={onFinishFailed}
                             autoComplete="off"
                          >
                             <Form.Item
                             label="INICIO"
                             name="initQuantity"
                             rules={[{ required: true, message: 'Please input your username!' }]}
                             >
                             <InputNumber/>
                             </Form.Item>

                             <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                             <Button type="primary" htmlType="submit">
                                REGISTRAR
                             </Button>
                             </Form.Item>
                          </Form>
                          <Form
                             name="basic"
                             labelCol={{ span: 11 }}
                             wrapperCol={{ span: 16 }}
                             initialValues={{ remember: true }}
                             onFinish={onFinish}
                             onFinishFailed={onFinishFailed}
                             autoComplete="off"
                          >
                             <Form.Item
                             label="INGRESO"
                             name="enterQuantity"
                             rules={[{ required: true, message: 'Please input your username!' }]}
                             >
                             <InputNumber />
                             </Form.Item>

                             <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                             <Button type="primary" htmlType="submit">
                                REGISTRAR
                             </Button>
                             </Form.Item>
                          </Form>
                          <Form
                             name="basic"
                             labelCol={{ span: 10 }}
                             wrapperCol={{ span: 16 }}
                             initialValues={{ remember: true }}
                             onFinish={onFinish}
                             onFinishFailed={onFinishFailed}
                             autoComplete="off"
                          >
                             <Form.Item
                             label="CIERRE"
                             name="closeQuantity"
                             rules={[{ required: true, message: 'Please input your username!' }]}
                             >
                             <InputNumber />
                             </Form.Item>

                             <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                             <Button type="primary" htmlType="submit">
                                REGISTRAR
                             </Button>
                             </Form.Item>
                          </Form>
                       </div>
                    }
                    </div>
                 </div>
              </div>
           </div>
            }
         </div>
         <Modal visible={showModalDaysClose} style={{ top: 20 }} title="RESUMEN DE CIERRES" onCancel={() => setShowModalDaysClose(false)} cancelButtonProps={{hidden: true}}>
            <div className="table-responsive closes-sumary-modal">
                  <table className="table items-table">
                  <tbody>
                     {
                        (allDaysOpeneds && allDaysOpeneds.length > 0) ? 
                        allDaysOpeneds.reverse().map(e => {
                           if (e && e.id)
                              return (
                                 <tr style={{cursor: "pointer"}} onClick={() => history.push(`/user-open/detail/${e.id}`)}>
                                 <td>
                                    <div className="media">
                                    <div className="media-body">
                                       <small className="mt-0 mb-1 font-w500">
                                          <a>
                                          {(e && e.state === 2) ? "CIERRE" : "APERTURA"} DEL DIA: <TimeAgo date= {(e && e.openedDay) || ""} formatter={formatter} />
                                          </a>
                                          </small> <br />
                                       <h5 className="">
                                          CODIGO: {(e && e.id) || null}
                                       </h5>
                                       <h5 className={(e && e.state === 2) && "text-red"}>
                                          ESTADO: {(e && getStateByCode(e.state)) || null}
                                       </h5>
                                       <h5 className="">
                                          USUARIO RESPONSABLE: {(e && e.closeInformation && e.closeInformation.authorizedUser) || null}
                                       </h5>
                                       <div className="star-review fs-14">
                                          <span className="ml-3 text-dark">
                                          TOTAL: soles
                                          </span>
                                       </div>
                                    </div>
                                    </div>
                                 </td>
                                 <td>
                                    <h4 className="my-0 text-secondary font-w600">
                                    3x
                                    </h4>
                                 </td>
                                 <td>
                                    <h4 className="my-0 text-secondary font-w600">
                                    $14.99
                                    </h4>
                                 </td>
                                 <td>
                                    <h4 className="my-0 text-secondary font-w600">
                                    $44.97
                                    </h4>
                                 </td>
                                 {/* <td>
                                    <Link
                                    to="/order"
                                    className="ti-close fs-28 text-danger las la-times-circle"
                                    />
                                 </td>  */}
                           </tr>
                           )
                        })
                        :
                        <tr>
                        <td>
                           NO TIENES PEDIDOS ASIGNADOS HASTA EL MOMENTO
                        </td>
                        </tr>
                     }
                  </tbody>
                  </table>
                  </div>
         </Modal>
         <Modal centered visible={isUpdating}>INICIANDO DIA, ESPERE...</Modal>
      </>
   );
};

export default EcomProductDetails;
