import React, { useState, useEffect } from "react";
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { Steps, Timeline, Carousel } from 'antd';
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import NotificationSound from '../.././images/notification.mp3'
import { Howl } from "howler";
import RestomaticOnlineServices from "./../../services/services";

const { Step } = Steps;
const formatter = buildFormatter(spanishStrings)
const moment = require('moment-timezone');

const DisplayRoomProducts = ({ reference, getConfigDisplayRef, getAllOrdersRef }) => {
  useEffect(() => {    
    // if (configLoaded && configLoaded.updated) soundPlay(NotificationSound, configLoaded)
  })
  let elem = document.documentElement;
  const [configLoaded, setConfigLoaded] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [goSound, setGoSound] = useState(false)
  const [allOrdersBD, setAllOrdersBD] = useState([])
  const [allOrdersGoToCashierBD, setAllOrdersGoToCashierBD] = useState([])
  const [allOrdersReadyToClientBD, setAllOrdersReadyToClientBD] = useState([])
  const soundPlay = (src, configLoaded) => {
    let configLoadedAux = { ...configLoaded }
    configLoadedAux.updated = false
    const sound = new Howl({src})
    sound.volume (1.0)
    sound.play()

    const serviceConfig = new RestomaticOnlineServices(reference);
    serviceConfig.updateConfigRoom(configLoadedAux.keyBD, configLoadedAux).then(res => {
      console.log("SE REGISTRO EN SALA")
    }, error => {
      console.log("NO SE REGISTRO EN SALA")
    })
  }
  console.log(elem)
  const onFullScreen = () => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };
  // onFullScreen()
  const filterOrdersOnlyToday = (date) => {
    let result = false;
      const todayDate = moment.tz(new Date(), "America/Lima").format()
      // console.log(todayDate)
      let initDay = todayDate.split("T")
      let finalDay = todayDate.split("T")
      initDay = initDay[0]+"T00:00:00-05:00"
      finalDay = finalDay[0]+"T23:59:59-05:00"
      initDay = Date.parse(initDay)
      finalDay = Date.parse(finalDay)
      date = Date.parse(date)
      console.log(initDay)
      console.log(finalDay)
      console.log(date)
      // if (moment.tz(Date.parse(date).isAfter(Date.parse(initDay)))) result = true
      if (initDay <= date && date <= finalDay) result = true
      return result
  }
  const getConfigUpdated = () => {
    let allOrdersAux = []
    let allIds = []
    getConfigDisplayRef.on("value", (snapshot) => {
      let result = []
      snapshot.forEach(e => {
        allIds.push(e.key)
      })
         if (snapshot.val() !== null) {
           allOrdersAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
           console.log(allOrdersAux)
           allOrdersAux = Object.values(allOrdersAux)
           allOrdersAux = allOrdersAux.map((e, index) => {
            console.log(allIds)
           if (allIds.length) {
           allIds.forEach(k => {
             e.keyBD = `${allIds[index]}`
           })
           }
           return e
        })
           console.log(allOrdersAux)
          if (allOrdersAux && allOrdersAux[0]) setConfigLoaded(allOrdersAux[0])
          console.log(allOrdersAux[0])
          
         }
         return;
    })
  }
  // if (configLoaded) soundPlay(NotificationSound)
  if (configLoaded === null) {
    getConfigUpdated()
  }
  const getAllOrdersFirebase = () => {
    let allIds = []
    let allOrdersAux = []
    getAllOrdersRef.on("value", (snapshot) => {
      let result = []
         if (snapshot.val() !== null) {
          snapshot.forEach(e => {
            allIds.push(e.key)
          })
           allOrdersAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
           allOrdersAux = Object.values(allOrdersAux)
           allOrdersAux = allOrdersAux.map((e, index) => {
              console.log(allIds)
             if (allIds.length) {
             allIds.forEach(k => {
               e.keyBD = `ORDERID${allIds[index]}`
             })
             }
             return e
          })
          if (allOrdersAux) setIsLoaded(true)
          // result = filterOrders(allOrdersAux, myWorkerDelivery)
          console.log(allOrdersAux)
          let auxList = []
          if (allOrdersAux) {
            allOrdersAux.forEach(e => {
              if (e && e.dateRegistered && e.state >= 2) {
                const result = filterOrdersOnlyToday(e.dateRegistered)
                if (result && e.deliveryInformation && e.deliveryInformation.infoClientFromRoom) {
                  auxList.push(e)
                }
              }
            })
            console.log(auxList)
            if (auxList) {
              // setGoSound(true)
              setAllOrdersBD(auxList)
              // let onlyWithCombos = auxList.map(a => a.products)
              // console.log(onlyWithCombos)
              // setAllCombosTodayBD(auxList)
            }
          }
         }
         return;
       }, (error) => {
         console.log("ERROR: " + error.code);
       });
    }
    // if (goSound) {
    //   console.log("ACA")
    //   soundPlay(NotificationSound)
    //   setGoSound(false)
    // }
    if (allOrdersBD && allOrdersBD.length === 0 && !isLoaded) getAllOrdersFirebase()
    console.log(allOrdersBD)
    console.log(configLoaded)
  return (
    <div className="row display-products-room">
      <div className="col-12">
              <div className="orders-list-room">
                <div className="row">
                <div className="col-6">
                  <h1>EN PREPARACION</h1>
                  </div>
                <div className="col-6">
                  <h1>PASE A CAJA</h1>
                  </div>
                </div>
                {/* <Steps>
                  <Step status="process" style={{fontWeight: 600, fontSize: "1.2em"}} title="EN PREPARACION" icon={<LoadingOutlined />} />
                  <Step status="process" style={{fontWeight: 600, fontSize: "1.2em"}} title="PASE A CAJA" icon={<SolutionOutlined />} />
                  {/* <Step status="finish" style={{fontWeight: 600, fontSize: "1.2em"}} title="PARA RECOGER" icon={<SmileOutlined />} /> 
                </Steps> */}
                <div className="row">
                  <div className="col-6">
                    <br />
                    <Timeline className="users-waiting">
                      {
                        allOrdersBD && allOrdersBD.map((e, index) => {
                          if (e && e.state === 2)
                            return (<Timeline.Item color="green" style={{fontSize: "1.6em", fontWeight: 700}}><input value={`ERS${e.orderNumber || ""} - ${(e && e.deliveryInformation && e.deliveryInformation.infoClientFromRoom.toUpperCase()) || ""}`} className="input-client" disabled /></Timeline.Item>)
                        })
                      }
                    </Timeline>
                  </div>
                  <div className="col-6">   
                    <br />
                    <Timeline className="users-waiting">
                    {
                        allOrdersBD && allOrdersBD.map((e, index) => {
                          if (e && e.state === 8)
                          return (<Timeline.Item color="green" style={{fontSize: "1.6em", fontWeight: 700}}><input value={`ERS${e.orderNumber || ""} - ${(e && e.deliveryInformation && e.deliveryInformation.infoClientFromRoom.toUpperCase()) || ""}`} className="input-client" disabled /></Timeline.Item>)
                        })
                      }
                    </Timeline>
                  </div>
                  {/* <div className="col-4">
                    <br />
                    {
                        allOrdersBD && allOrdersBD.map((e, index) => {
                          if (e && e.state === 3)
                          return (<Timeline.Item color="green" style={{fontSize: "1.6em", fontWeight: 700}}><input value={`ERS${e.orderNumber || ""} - ${(e && e.deliveryInformation && e.deliveryInformation.infoClientFromRoom.toUpperCase()) || ""}`} className="input-client" disabled /></Timeline.Item>)
                        })
                      }
                  </div> */}
                </div>
              </div>
              <Carousel autoplay dots={false}>
                {
                  Array.from({length: 55}, (_, i) => i + 1).map(e => {
                    return (
                      <div>
                        <img src={`images/rey-sabor/sala/${e}.jpg`} />
                      </div>
                    )
                  })
                }
              {/* <div>
                <img src="images/rey-sabor/carta1-2.jpg" />
              </div>
              <div>
                <img src="images/rey-sabor/carta1-3.jpg" />
              </div>
              <div>
                <img src="images/rey-sabor/carta1-1.jpg" />
              </div> */}
            </Carousel>
        </div>
      {/* </div> */}
    </div>
  )
}

export default DisplayRoomProducts;