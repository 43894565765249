import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

// images
import image01 from "../../../images/product/1.jpg";
import image02 from "../../../images/tab/1.jpg";
import image03 from "../../../images/tab/2.jpg";
import image04 from "../../../images/tab/3.jpg";
import image05 from "../../../images/tab/4.jpg";

import { Card, Form, InputNumber, Button, Modal } from 'antd';
import { Dropdown } from "react-bootstrap";
import RestomaticOnlineServices from "../../../services/services";
import TimeAgo from 'react-timeago'
import {OPEN_DAYS_STATES, PAY_TYPES, USERS} from "../../../constants";
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import InfoChikens from "./infoChickens";
import InfoDrinks from "./infoDrinks";
const formatter = buildFormatter(spanishStrings)
const moment = require('moment-timezone');

const DetailOpenCloseDay = ({reference, getAllOpenedsDays, getAllOrdersRef, getAllDishes, match}) => {
  let id = window.location.href.split("/") || ""
  id = id && id.pop()
  console.log(id)
const myCode = localStorage.getItem("workerCod") || ""
   const history = useHistory();
   const [optionSelected, setOptionSelected] = useState(null)
   const [initDay, setInitDay] = useState(true)
   const [allDaysOpeneds, setAllDaysOpeneds] = useState([])
   const [showModalDaysClose, setShowModalDaysClose] = useState(false)
   const [showModalAllCombos, setShowModalAllCombos] = useState(false)
   const [showModalAllDrinks, setShowModalAllDrinks] = useState(false)
   const [daySelected, setDaySelected] = useState(null)
   const [allOrdersBD, setAllOrdersBD] = useState([])
   const [allCombosTodayBD, setAllCombosTodayBD] = useState([])
   const [allDrinksTodayBD, setAllDrinksTodayBD] = useState([])
   const [allDishesBD, setAllDishesBD] = useState([])
   const [isLoaded, setIsLoaded] = useState(false)

   let idCode = window.location.href || ""
   idCode = idCode && idCode.split("/").pop()
   const gridStyle = {
      cursor: "pointer",
      textAlign: 'center',
    };
    const showModalOpenedDays = () => {
      setShowModalDaysClose(true)
    }
    const getAllDishesFirebase = () => {
      let allIds = []
      let allDishesBDAux = []
      getAllDishes.on("value", (snapshot) => {
           if (snapshot.val() !== null) {
            snapshot.forEach(e => {
              allIds.push(e.key)
            })
             allDishesBDAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
             allDishesBDAux = Object.values(allDishesBDAux)
             allDishesBDAux = allDishesBDAux.map((e, index) => {
                console.log(allIds)
               if (allIds.length) {
               allIds.forEach(k => {
                 if (!e.keyBD) e.keyBD = `DISHID${allIds[index]}`
               })
               }
               return e
            })
            if (allDishesBDAux.length) {
              setAllDishesBD(allDishesBDAux)
              let combos = []
              let drinks = []
              allDishesBDAux.forEach(k => {
                if (k && k.name && (k.name.includes("Combo") || k.name.includes("combo"))) {
                  combos.push(k)
                }
              })
              allDishesBDAux.forEach(k => {
                if (k && k.generalCategory && k.generalCategory === 3) {
                  drinks.push(k)
                }
              })
              console.log(combos)
              console.log(drinks, "allDishesBDAux")
              if (combos) setAllCombosTodayBD(combos)
              if (drinks) setAllDrinksTodayBD(drinks)
              // setAllProducts(allDishesBDAux)
             console.log(allDishesBDAux, "allDishesBDAux")
            }
           }
           return;
         }, (error) => {
           console.log("ERROR: " + error.code);
         });
      }
      console.log(allDrinksTodayBD)
      console.log(allCombosTodayBD)
      if (allDishesBD.length === 0) getAllDishesFirebase()
    const selectOption = (option) => {
      console.log(option)
      const auxDaySelected = {...daySelected}
      if (option && auxDaySelected && auxDaySelected.keyBD) {
         setOptionSelected(option)
      }
    }
    const finishDay = () => {
      const auxDaySelected = {...daySelected}
      const service = new RestomaticOnlineServices(reference)

      auxDaySelected.state = 2
      auxDaySelected.closeInformation = {}
      auxDaySelected.closeInformation.authorizedUser = myCode
      auxDaySelected.closeInformation.date = moment.tz(new Date(), "America/Lima").format()

      auxDaySelected.closeInformation.totalOrdersList = []
      auxDaySelected.closeInformation.totalDishesWithSoldSunids = []

      auxDaySelected.closeInformation.totalOrdersList = allOrdersBD
      auxDaySelected.closeInformation.totalDishesWithSoldSunids = allDishesBD
         service.updateOpenDay(auxDaySelected.keyBD, auxDaySelected).then(res => {
            getAllopenedDaysFirebase()
            alert("Este día ha sido cerrado")
            history.push("/user-open")
         })
    }
    const filterOrdersOnlyToday = (date) => {
      let result = false;
        const todayDate = moment.tz(new Date(), "America/Lima").format()
        // console.log(todayDate)
        let initDay = todayDate.split("T")
        let finalDay = todayDate.split("T")
        initDay = initDay[0]+"T00:00:00-05:00"
        finalDay = finalDay[0]+"T23:59:59-05:00"
        initDay = Date.parse(initDay)
        finalDay = Date.parse(finalDay)
        date = Date.parse(date)
        console.log(initDay)
        console.log(finalDay)
        console.log(date)
        // if (moment.tz(Date.parse(date).isAfter(Date.parse(initDay)))) result = true
        if (initDay <= date && date <= finalDay) result = true
        return result

    }
    const getAllOrdersFirebase = () => {
      let allIds = []
      let allOrdersAux = []
      getAllOrdersRef.on("value", (snapshot) => {
        let result = []
           if (snapshot.val() !== null) {
            snapshot.forEach(e => {
              allIds.push(e.key)
            })
             allOrdersAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
             allOrdersAux = Object.values(allOrdersAux)
             allOrdersAux = allOrdersAux.map((e, index) => {
                console.log(allIds)
               if (allIds.length) {
               allIds.forEach(k => {
                 e.keyBD = `ORDERID${allIds[index]}`
               })
               }
               return e
            })
            if (allOrdersAux) setIsLoaded(true)
            // result = filterOrders(allOrdersAux, myWorkerDelivery)
            console.log(allOrdersAux)
            let auxList = []
            if (allOrdersAux) {
              allOrdersAux.forEach(e => {
                if (e && e.dateRegistered && e.state >= 2) {
                  const result = filterOrdersOnlyToday(e.dateRegistered)
                  if (result) {
                    auxList.push(e)
                  }
                }
              })
              console.log(auxList)
              if (auxList) {
                setAllOrdersBD(auxList)
                // let onlyWithCombos = auxList.map(a => a.products)
                // console.log(onlyWithCombos)
                // setAllCombosTodayBD(auxList)
              }
            }
           }
           return;
         }, (error) => {
           console.log("ERROR: " + error.code);
         });
      }
      console.log(allCombosTodayBD)
      if (allOrdersBD.length === 0 && !isLoaded) getAllOrdersFirebase()
      console.log(allOrdersBD)
    const onFinish = (values) => {
      console.log(values)
      if (values && daySelected && daySelected.keyBD) {
         const auxDaySelected = {...daySelected}
         auxDaySelected.chikensInformation = (daySelected && daySelected.chikensInformation) ? {...daySelected.chikensInformation} : {}
         if (values.initQuantity) {
            console.log('Success:', values);
            auxDaySelected.chikensInformation = {...auxDaySelected.chikensInformation}
            auxDaySelected.chikensInformation.initQuantity = values.initQuantity || null
            auxDaySelected.chikensInformation.dateInit = moment.tz(new Date(), "America/Lima").format()
         }
         if (values.enterQuantity) {
            console.log('Success:', values);
            auxDaySelected.chikensInformation = {...auxDaySelected.chikensInformation}
            auxDaySelected.chikensInformation.enters = (auxDaySelected.chikensInformation && auxDaySelected.chikensInformation.enters) ? auxDaySelected.chikensInformation.enters : []
            const auxObj = {
               enterQuantity: values.enterQuantity || null,
               dateEnter: moment.tz(new Date(), "America/Lima").format()
            }
            console.log(auxDaySelected.chikensInformation.enters)
            auxDaySelected.chikensInformation.enters.push(auxObj)
         }
         if (values.closeQuantity) {
            console.log('Success:', values);
            auxDaySelected.chikensInformation = {...auxDaySelected.chikensInformation}
            auxDaySelected.chikensInformation.closeQuantity = values.closeQuantity || null
            auxDaySelected.chikensInformation.dateClose = moment.tz(new Date(), "America/Lima").format()
         }
         const service = new RestomaticOnlineServices(reference)

         service.updateOpenDay(auxDaySelected.keyBD, auxDaySelected).then(res => {
            getAllopenedDaysFirebase()
            setDaySelected(auxDaySelected)
            alert("Información actualizada")
         })
      }
    };
        const onFinishDrinks = (values) => {
      if (values && daySelected && daySelected.keyBD) {
         const auxDaySelected = {...daySelected}
         auxDaySelected.drinksInformation = (daySelected && daySelected.drinksInformation) ? {...daySelected.drinksInformation} : {}
         if (values.initQuantity) {
            console.log('Success:', values);
            auxDaySelected.drinksInformation = {...auxDaySelected.drinksInformation}
            auxDaySelected.drinksInformation.initQuantity = values.initQuantity || null
            auxDaySelected.drinksInformation.dateInit = moment.tz(new Date(), "America/Lima").format()
         }
         if (values.enterQuantity) {
            console.log('Success:', values);
            auxDaySelected.drinksInformation = {...auxDaySelected.drinksInformation}
            auxDaySelected.drinksInformation.enters = (auxDaySelected.drinksInformation && auxDaySelected.drinksInformation.enters) ? auxDaySelected.drinksInformation.enters : []
            const auxObj = {
               enterQuantity: values.enterQuantity || null,
               dateEnter: moment.tz(new Date(), "America/Lima").format()
            }
            console.log(auxDaySelected.drinksInformation.enters)
            auxDaySelected.drinksInformation.enters.push(auxObj)
         }
         if (values.closeQuantity) {
            console.log('Success:', values);
            auxDaySelected.drinksInformation = {...auxDaySelected.drinksInformation}
            auxDaySelected.drinksInformation.closeQuantity = values.closeQuantity || null
            auxDaySelected.drinksInformation.dateClose = moment.tz(new Date(), "America/Lima").format()
         }
         const service = new RestomaticOnlineServices(reference)

         service.updateOpenDay(auxDaySelected.keyBD, auxDaySelected).then(res => {
            getAllopenedDaysFirebase()
            setDaySelected(auxDaySelected)
            alert("Información actualizada")
         })
      }
    };
    const getAllopenedDaysFirebase = () => {
      let allIds = []
      let daysOpenedsAux = []
      getAllOpenedsDays.on("value", (snapshot) => {
           if (snapshot.val() !== null) {
            snapshot.forEach(e => {
              allIds.push(e.key)
            })
             daysOpenedsAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
             daysOpenedsAux = Object.values(daysOpenedsAux)
             daysOpenedsAux = daysOpenedsAux.map((e, index) => {
                console.log(allIds)
               if (allIds.length) {
               allIds.forEach(k => {
                 if (e && !e.keyBD) e.keyBD = `ORDERID${allIds[index]}`
               })
               }
               return e
            })
            
            setAllDaysOpeneds(daysOpenedsAux)
            if (daysOpenedsAux) {
               daysOpenedsAux.forEach(j => {
                  if (j.id === Number(idCode)) setDaySelected(j)
               })
            }
            console.log(daysOpenedsAux)
            console.log(daySelected)
            let result = {}
             console.log(daysOpenedsAux, "daysOpenedsAux")
           }
           return;
         }, (error) => {
           console.log("ERROR: " + error.code);
         });
      }
      if (allDaysOpeneds.length === 0) getAllopenedDaysFirebase()
      console.log(daySelected)
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };
    const onFinishFailedDrinks = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };
    const initDayFn = (obj2) => {
       const obj = { ...obj2,
         openedDay:  moment.tz(new Date(), "America/Lima").format(),
         id: Math.floor(Math.random() * (1000000 - 10000)) + 100
       }
       
       console.log(obj)
      //  setDaySelected(obj)
      const service = new RestomaticOnlineServices(reference)
      service.saveOpenOfDay(obj).then(res => {
         setInitDay(true)
         console.log(res)
         if (obj) getAllopenedDaysFirebase()
         history.push(`/user-open/detail/${obj.id}`)
       })
    }
    const goBack = () => {
      setDaySelected(null)
      setInitDay(false)
      history.push(`/user-open`)
    }
    const goBack2 = () => {
      // history.push(`/user-open`)
      setOptionSelected(null)
      // setInitDay(false)
    }
    const showModalForDeliveryChatBox = (option) => {
      console.log(option)
      // if (option === "add") setShowModalForDelivery(true)
      
    }
    const getStateByCode = (state) => {
      let result = ""
      if (state) {
         OPEN_DAYS_STATES.forEach(e => {
          if (e.value === state) result = e.label
        })
      }
      return result;   
    }
    const openModalAllCombos = () => {
      let auxList = []
      setShowModalAllCombos(true)
    }
    const openModalAllDrinks = () => {
      let auxList = []
      setShowModalAllDrinks(true)
    }
    const closeModalAllCombos = () => {
      setShowModalAllCombos(false)
    }
    const closeModalAllDrinks = () => {
      setShowModalAllDrinks(false)
    }
    const getLastCloseDayChicken = (day, withDayOpened) => {
      console.log(day)
      console.log(allDaysOpeneds)
      let result = {};
      let resultQ = null;
      if (day && allDaysOpeneds) {
        if (!withDayOpened) result = allDaysOpeneds[allDaysOpeneds.length-1]
        if (withDayOpened) result = allDaysOpeneds[allDaysOpeneds.length-2]
        
      }
      if (result && result.chikensInformation && result.chikensInformation.closeQuantity) resultQ = result.chikensInformation.closeQuantity;
      console.log(result)
      console.log(resultQ)
      return resultQ;
    }
    const getLastCloseDayDrinks = (day) => {
      let result = {};
      if (day && allDaysOpeneds) {
        result = allDaysOpeneds[allDaysOpeneds.length-2]
      }
      if (result && result.drinksInformation && result.drinksInformation.closeQuantity) return result.drinksInformation.closeQuantity;
      return result;
    }
    const calculateTotalChikensAddEnters = (enters, initQuantity) => {
      let result = 0

      if (enters) {
        console.log(enters)
        const total = enters.map(e => e.enterQuantity)
        if (total) {
          result = total.reduce((a,b) => a+b)
          
          if (initQuantity > 0) result = result + initQuantity
        }
      }

      return result;
    }
    
    const calculateTotalDrinksAddEnters = (enters, initQuantity) => {
      let result = 0

      if (enters) {
        const total = enters.map(e => e.enterQuantity)
        if (total) {
          result = total.reduce((a,b) => a+b)
          
          if (initQuantity > 0) result = result + initQuantity
        }
      }

      return result;
    }
   return (
      <>
         <div className="row page-titles mx-0">
            <div className="col-sm-6 p-md-0">
               <div className="welcome-text">
                  {
                     (daySelected && daySelected.state === 1) ?
                     <h4>HOLA!,<br/> INICIO DE LA APERTURA DE HOY</h4>
                     :
                  <h4>HOLA!,<br/>TE ENCUENTRAS VIENDO EL RESUMEN DE CIERRE</h4>
                  }
               </div>
            </div>
            <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
               <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <a>APERTURA</a>
                  </li>
                  <li className="breadcrumb-item active">
                     <a>CONFIGURACION</a>
                  </li>
               </ol>
               <h5>FECHA DE APERTURA: <TimeAgo date={(daySelected && daySelected.openedDay)} formatter={formatter} /></h5>
               {(daySelected && daySelected.closeInformation) && 
                  <h5>FECHA DE CIERRE: {(daySelected && daySelected.closeInformation && daySelected.closeInformation.date)}</h5>}
               <h5 className={(daySelected && daySelected.state === 2) && "text-red"}>ESTADO: {(daySelected && getStateByCode(daySelected.state))}</h5>
               <h5>CODIGO: {(daySelected && daySelected.id)}</h5>
            </div>
         </div>
         <div className="row">
            {
               (optionSelected && daySelected) &&
            <button className="btn btn-primary col-2"  style={{margin: "5px"}} onClick={() => goBack2()} danger>
                  ATRAS 2
               </button>
            }
                {
               (daySelected && initDay && !optionSelected) &&
            <button className="btn btn-primary col-2"  style={{margin: "5px"}} onClick={() => goBack()} danger>
                  ATRAS 1
               </button>
            }
            {
               // !initDay &&
               <Button type="primary" className="col-2" style={{margin: "5px"}}  onClick={() => showModalOpenedDays()} danger>
                  VER CIERRES
                </Button>
            }
                {
                   (initDay && !optionSelected) &&
                   <>
                     <Button type="primary" className="col-2" style={{margin: "5px"}} onClick={() => openModalAllCombos()} danger>
                       VER COMBOS
                    </Button>
                    <Button type="primary" className="col-2" style={{margin: "5px"}} onClick={() => openModalAllDrinks()} danger>
                       VER BEBIDAS
                    </Button>
                    </>
                }
                {
                   (initDay && !optionSelected) &&
                   <button className="btn btn-primary col-2"  style={{margin: "5px"}} disabled={daySelected && daySelected.state === 2} onClick={() => finishDay()}>
                     CERRAR DIA
                  </button>
                }
            {
               (!initDay) ?
               <Button type="primary" onClick={() => initDayFn({openedDay: "", id: 0})} danger>
               INICIAR DIA
           </Button>
           :
           <div className="col-lg-12">
              <div className="card">
                 <div className="card-body">
                    <div className="site-card-wrapper">
                     {
                       !optionSelected &&
                       <Card title="INVENTARIO" className="card-warehouse">
                          <Card.Grid className="col-sm-6 col-md-3 col-lg-3" onClick={() => selectOption(1)} style={gridStyle}>POLLOS</Card.Grid>
                          <Card.Grid className="col-sm-6 col-md-3 col-lg-3" onClick={() => selectOption(2)} style={gridStyle}>PLATOS</Card.Grid>
                          <Card.Grid className="col-sm-6 col-md-3 col-lg-3" onClick={() => selectOption(3)} style={gridStyle}>CARNES</Card.Grid>
                          <Card.Grid className="col-sm-6 col-md-3 col-lg-3" onClick={() => selectOption(4)} style={gridStyle}>BEBIDAS</Card.Grid>
                       </Card>
                      }
                      {
                       optionSelected === 1 && 
                       <InfoChikens calculateTotalChikensAddEnters={calculateTotalChikensAddEnters} 
                       daySelected={daySelected} allDaysOpeneds={allDaysOpeneds} 
                       getLastCloseDayChicken={getLastCloseDayChicken} 
                       onFinish={onFinish} onFinishFailed={onFinishFailed}/>
                      }
                      
                      {
                       optionSelected === 4 && 
                       <InfoDrinks calculateTotalChikensAddEnters={calculateTotalDrinksAddEnters} daySelected={daySelected} allDaysOpeneds={allDaysOpeneds} getLastCloseDayDrinks={getLastCloseDayDrinks} onFinish={onFinishDrinks} onFinishFailed={onFinishFailedDrinks}/>
                      }
                    </div>
                 </div>
              </div>
           </div>
            }
         </div>
         <Modal visible={showModalDaysClose} style={{top: "20px"}} title="RESUMEN DE CIERRES" onCancel={() => setShowModalDaysClose(false)} cancelButtonProps={{hidden: true}}>
            <div className="table-responsive modal-sumary-combos">
                    <div className="name-for-mobile">
                      <h2 className="text-red">
                        Hola!
                      </h2>
                    </div>
                      <table className="table items-table">
                        <tbody>
                          {
                            (allDaysOpeneds && allDaysOpeneds.length > 0) ? 
                            allDaysOpeneds.reverse().map(e => {
                              return (
                                <tr style={{cursor: "pointer"}} onClick={() => null}>
                                <td>
                                  <div className="media">
                                    <div className="media-body">
                                      <small className="mt-0 mb-1 font-w500">
                                        <a>
                                          FECHA APERTURA: <TimeAgo date={(e && e.openedDay)} formatter={formatter} />
                                          <br />
                                          {(e && e.closeInformation) && 
                                            <a className="text-red">FECHA DE CIERRE: {(e && e.closeInformation && e.closeInformation.date)}</a>}
                                        </a> </small>
                                      <h5 className="">
                                          CODIGO: {e.id}
                                      </h5>
                                      <h5 className="">
                                          USUARIO RESPONSABLE: 
                                      </h5>
                                      {/* <div className="star-review fs-14">
                                        <span className="ml-3 text-dark">
                                          TOTAL: soles
                                        </span>
                                      </div> */}
                                    </div>
                                  </div>
                                </td>
                                {/* <td>
                                  <h4 className="my-0 text-secondary font-w600">
                                    3x
                                  </h4>
                                </td>
                                <td>
                                  <h4 className="my-0 text-secondary font-w600">
                                    $14.99
                                  </h4>
                                </td> */}
                                <td>
                                  <h4 className="my-0 font-w600 text-red">
                                  {(daySelected && getStateByCode(e.state))}
                                  </h4>
                                </td>
                                {/* <td>
                                  <Link
                                    to="/order"
                                    className="ti-close fs-28 text-danger las la-times-circle"
                                  />
                                </td>  */}
                          </tr>
                              )
                            })
                            :
                            <tr>
                              <td>
                                NO TIENES PEDIDOS ASIGNADOS HASTA EL MOMENTO
                              </td>
                            </tr>
                          }
                        </tbody>
                      </table>
                      </div>
         </Modal>
         <Modal visible={showModalAllCombos} title="RESUMEN VENTA DE COMBOS" cancelText="CERRAR" okButtonProps={{hidden: true}} width={1000} style={{ top: 20 }} onCancel={() => closeModalAllCombos()}>
         <div className="table-responsive modal-sumary-combos">
           <h5 className="text-red">TOTAL DE COMBOS: {allCombosTodayBD && allCombosTodayBD.length}</h5>
            <div id="coustomer_table" className="dataTables_wrapper no-footer">
              <table
                id="example5"
                className="display mb-4 dataTablesCard dataTable no-footer w-100"
                style={{ minWidth: 845 }}
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_asc"
                      tabIndex={0}
                      aria-controls="example5"
                      // rowSpan={1}
                      // colSpan={1}
                      style={{ width: "40px" }}
                    >
                     Nombre
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Join Date: activate to sort column ascending"
                      style={{ width: 82 }}
                    >
                      Descripción
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Customer Name: activate to sort column ascending"
                      style={{ width: "80px" }}
                    >
                      Precio <br /> venta
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Customer Name: activate to sort column ascending"
                      style={{ width: "80px" }}
                    >
                      Precio <br /> personal
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Location: activate to sort column ascending"
                      style={{ width: 104 }}
                    >
                      Cantidad combo
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Total Spent: activate to sort column ascending"
                      style={{ width: 50 }}
                    >
                      Cant de bebidas
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Last Order: activate to sort column ascending"
                      style={{ width: "100px" }}
                    >
                      Cantidad de pollo
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Last Order: activate to sort column ascending"
                      style={{ width: 90 }}
                    >
                      Monto
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    allCombosTodayBD && allCombosTodayBD.map(e => {
                      return (
                        <tr role="row" className="odd">
                    <td className="sorting_1 text-red">{e.name || ""}</td>
                    <td>{e.desc || ""}</td>
                    <td><b>S/ {e.price || ""}</b></td>
                    <td><b>S/ {e.price || ""}</b></td>
                    <td>{e.soldsUnids || 0}</td>
                    <td>{(e && e.drinkIdIncluded && e.soldsUnids > 0) ? e.soldsUnids : 0}</td>
                    <td>
                      <span className="btn btn-sm light btn-secondary fs-16">
                      {Number(e.chickenQuantity)*e.soldsUnids || 0}
                      </span>
                    </td>
                    <td>
                      <span className="btn btn-sm light btn-secondary fs-16" style={{padding: "10px 5px"}}>
                        S/ {Number(e.price)*e.soldsUnids || 0}
                      </span>
                    </td>
                    {/* <td>
                      <Dropdown className="dropdown ml-auto text-right">
                        <Dropdown.Toggle
                          variant=""
                          className="i-false p-0 btn-link"
                          data-toggle="dropdown"
                        >
                          <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <circle fill="#000000" cx={5} cy={12} r={2} />
                              <circle fill="#000000" cx={12} cy={12} r={2} />
                              <circle fill="#000000" cx={19} cy={12} r={2} />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                          <a className="dropdown-item" href="#">
                            View Detail
                          </a>
                          <a className="dropdown-item" href="#">
                            Edit
                          </a>
                          <a className="dropdown-item" href="#">
                            Delete
                          </a>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>                     */}
                  </tr>
                      )
                    })
                  }                  
                </tbody>
              </table>
              {/* <div className="d-sm-flex text-center justify-content-between">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/general-customers"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                  >
                    Previous
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="/general-customers"
                        className={`paginate_button  ${
                          activePag.current === i ? "current" : ""
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/general-customers"
                    onClick={() =>
                     //  activePag.current + 1 < paggination.length &&
                     //  onClick(activePag.current + 1)
                     null
                    }
                  >
                    Next
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
         </Modal>
         <Modal visible={showModalAllDrinks} title="RESUMEN VENTA DE BEBIDAS" cancelText="CERRAR" okButtonProps={{hidden: true}} width={1000} style={{ top: 20 }} onCancel={() => closeModalAllDrinks()}>
         <div className="table-responsive modal-sumary-combos">
           <h5 className="text-red">TOTAL DE BEBIDAS: {allDrinksTodayBD && allDrinksTodayBD.length}</h5>
            <div id="coustomer_table" className="dataTables_wrapper no-footer">
              <table
                id="example5"
                className="display mb-4 dataTablesCard dataTable no-footer w-100"
                style={{ minWidth: 845 }}
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_asc"
                      tabIndex={0}
                      aria-controls="example5"
                      // rowSpan={1}
                      // colSpan={1}
                      style={{ width: "40px" }}
                    >
                     Nombre
                    </th>
                    {/* <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Join Date: activate to sort column ascending"
                      style={{ width: 82 }}
                    >
                      Descripción
                    </th> */}
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Customer Name: activate to sort column ascending"
                      style={{ width: "180px", textAlign: "center" }}
                    >
                      Precio
                      venta
                    </th>
                   <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Customer Name: activate to sort column ascending"
                      style={{ width: "180px", textAlign: "center" }}
                    >
                      Precio
                      personal
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Location: activate to sort column ascending"
                      style={{ width: 104, textAlign: "center" }}
                    >
                      Cantidad
                    </th>
                    {/* <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Total Spent: activate to sort column ascending"
                      style={{ width: 50 }}
                    >
                      Cantidad de bebidas
                    </th> */}
                    {/* <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Last Order: activate to sort column ascending"
                      style={{ width: 100 }}
                    >
                      Cant de pollo
                    </th> */}
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Last Order: activate to sort column ascending"
                      style={{ width: 50 }}
                    >
                      Monto
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    allDrinksTodayBD && allDrinksTodayBD.map(e => {
                      return (
                        <tr role="row" className="odd">
                    <td className="sorting_1 text-red">{e.name || ""}</td>
                    {/* <td>{e.desc || ""}</td> */}
                    <td style={{textAlign: "center"}}>S/ {e.price}</td>
                    <td style={{textAlign: "center"}}>S/ {e.price}</td>
                    <td style={{textAlign: "center"}}>{e.soldsUnids}</td>
                    {/* <td>$34.41</td> */}
                    {/* <td>
                      <span className="btn btn-sm light btn-secondary fs-16">
                        $34.41
                      </span>
                    </td> */}
                    <td>
                      <span className="btn btn-sm light btn-secondary fs-16">
                        S/ {e.price * e.soldsUnids}
                      </span>
                    </td>
                    {/* <td>
                      <Dropdown className="dropdown ml-auto text-right">
                        <Dropdown.Toggle
                          variant=""
                          className="i-false p-0 btn-link"
                          data-toggle="dropdown"
                        >
                          <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <circle fill="#000000" cx={5} cy={12} r={2} />
                              <circle fill="#000000" cx={12} cy={12} r={2} />
                              <circle fill="#000000" cx={19} cy={12} r={2} />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                          <a className="dropdown-item" href="#">
                            View Detail
                          </a>
                          <a className="dropdown-item" href="#">
                            Edit
                          </a>
                          <a className="dropdown-item" href="#">
                            Delete
                          </a>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>                     */}
                  </tr>
                      )
                    })
                  }                  
                </tbody>
              </table>
            </div>
          </div>
         </Modal>
      </>
   );
};

export default DetailOpenCloseDay;
