import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///
import icon1 from "../../../images/icon1.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //  this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper isMoble-active">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
    state = {
      btn2: null,
      aaa2: null
    }
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    this.setState({btn2: btn, aaa2: aaa})

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }
  
  toggleFun2c = () => {
    return this.state.aaa2.classList.toggle("menu-toggle");
  }
  closeNavLeft = () => {
    this.props.onClick()
    // this.toggleFun2c()
  }
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let deshBoard = [
        "",
        "orders",
        "events",
        "order-list",
        "general-customers",
        "reviews",
      ],
      app = [
        "app-profile",
        "app-calender",
        "email-compose",
        "email-inbox",
        "email-read",
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "post-details",
        "ecom-product-detail",
      ],
      email = ["email-compose", "email-inbox", "email-read"],
      shop = [
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "ecom-product-detail",
      ],
      charts = [
        "chart-rechart",
        "chart-flot",
        "chart-chartjs",
        "chart-chartist",
        "chart-sparkline",
        "chart-apexchart",
      ],
      bootstrap = [
        "ui-accordion",
        "ui-badge",
        "ui-alert",
        "ui-button",
        "ui-modal",
        "ui-button-group",
        "ui-list-group",
        "ui-media-object",
        "ui-card",
        "ui-carousel",
        "ui-dropdown",
        "ui-popover",
        "ui-progressbar",
        "ui-tab",
        "ui-typography",
        "ui-pagination",
        "ui-grid",
      ],
      plugins = [
        "uc-select2",
        "uc-nestable",
        "uc-sweetalert",
        "uc-toastr",
        "uc-noui-slider",
        "map-jqvmap",
      ],
      widget = ["widget-basic"],
      forms = [
        "form-element",
        "form-wizard",
        "form-editor-summernote",
        "form-pickers",
        "form-validation-jquery",
      ],
      table = ["table-bootstrap-basic", "table-datatable-basic"],
      pages = [
        "page-register",
        "page-login",
        "page-lock-screen",
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ],
      error = [
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
      ];

        const role = Number(localStorage.getItem("role")) || null
        console.log(role)
    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="flaticon-381-networking"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
              <ul aria-expanded="false"  style={{top: "0"}}>                
                {
                  (role && (role === 1)) &&
                  <li style={{backgroundColor: "white"}}>
                  <Link
                    className={`${path === "" ? "mm-active" : ""}`}
                    to="/"
                    onClick={() => this.closeNavLeft()}
                  >
                    INICIO
                  </Link>
                </li>
                }
                {
                  (role && (role === 1 || role === 4)) &&
                <li style={{backgroundColor: "white"}}>
                  <Link
                    className={`${path === "orders" ? "mm-active" : ""}`}
                    onClick={() => this.closeNavLeft()}
                    to="/orders"
                  >
                    MOZO
                  </Link>
                </li>
                }
                {
                  (role && (role === 1 || role === 2)) &&
                <li style={{backgroundColor: "white"}}>
                  <Link
                    className={`${path === "reviews" ? "mm-active" : ""}`}
                    onClick={() => this.closeNavLeft()}
                    to="/reviews"
                  >
                    COCINA
                  </Link>
                </li>
                }
                {
                  (role && (role === 1 || role === 3)) &&
                  <li style={{backgroundColor: "white"}}>
                   <Link
                      className={`${path === "order-list" ? "mm-active" : ""}`}
                      onClick={() => this.closeNavLeft()}
                      to="/order-list"
                    >
                      TOTAL PEDIDOS
                    </Link>
                  </li>
                }
                {
                  (role && (role === 6)) &&
                  <li style={{backgroundColor: "white"}}>
                   <Link
                      className={`${path === "order-list" ? "mm-active" : ""}`}
                      onClick={() => this.closeNavLeft()}
                      to="/delivery"
                    >
                      DELIVERY
                    </Link>
                  </li>
                } 
                {
                  (role && (role === 1)) &&
                  <li style={{backgroundColor: "white"}}>
                   <Link
                      className={`${path === "order-list" ? "mm-active" : ""}`}
                      onClick={() => this.closeNavLeft()}
                      to="/ecom-customers"
                    >
                      MOTORIZADOS
                    </Link>
                  </li>
                }
                </ul>
                </li>
          </MM>

          <div className="add-menu-sidebar">
            <img src={icon1} alt="" />
            <p>Bienvenido al RESTOMATIC, sistema de gestión online para restaurantes</p>
            {/* <Link to="#" className="btn btn-primary btn-block light">
              + Agregar plato
            </Link> */}
          </div>
          <div className="copyright">
            <p>
              <strong>RESTOMATIC</strong> © 2021 Todos
              los derechos reservados
            </p>
            <p>
              Desarrollado por <i className="fa fa-heart" /> Stars Corporation
            </p>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
