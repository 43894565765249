class RestomaticOnlineServices {
  constructor(ref) {
    this.ref = ref;
  }
  updateStateForMotorcycle = (id, motorcycle) => {
    const aux = id && id.replace('MOTORCYCLEID', '')

    if (this.ref) {
      const restomaticRef = this.ref.child("AllMotorcyclesBD");

      return restomaticRef.child(aux).update(motorcycle).then((res) => {
        return {
          message: "Actualización exitosa",
          response: res
    }
      }, error => {
        return {
          message: "Actualización fallída",
          error: error
         }
      });
    }
  }
  saveOpenOfDay = (obj) => {
    if (this.ref) {
      const restomaticRef = this.ref.child("warehouse");
      return restomaticRef.push(obj)
    }
  }
  updateAllDishesBD = (list) => {
    if (this.ref) {
      const restomaticRef = this.ref.child("dishesBD");
      return restomaticRef.push(list)
    }
  }
  insertDishes = (obj) => {
    if (this.ref) {
      const restomaticRef = this.ref.child("tables");
      return restomaticRef.push(obj)
    }
  }
  updateOpenDay = (id, order) => {
    const aux = id && id.replace('ORDERID', '')
		const restomaticRef = this.ref.child("warehouse");

		return restomaticRef.child(aux).update(order).then((res) => {
      return {
        message: "Actualización exitosa",
        response: res
  }
    }, error => {
      return {
        message: "Actualización fallída",
        error: error
       }
    });
  }
  updateDishUsed = (id, dish) => {
    const aux = id && id.replace('DISHID', '')
		const restomaticRef = this.ref.child("dishesBD");

		return restomaticRef.child(aux).update(dish).then((res) => {
      return {
        message: "Actualización exitosa",
        response: res
  }
    }, error => {
      return {
        message: "Actualización fallída",
        error: error
       }
    });
  }
  saveOrder = (obj) => {
    if (this.ref) {
      const restomaticRef = this.ref.child("orders");
      return restomaticRef.push(obj).then((res) => {
        console.log(res._delegate._path.pieces_)
        const obj = {
          res,
          data: res._delegate._path.pieces_
        }
        return {
              message: "registro exitoso",
              response: obj
        }
      }, error => {
            return {
              message: "registro fallído",
              error: error
             }
      })
    }
	}

  updateOrderDB = (id, order) => {
    const aux = id && id.replace('ORDERID', '')
		const restomaticRef = this.ref.child("orders");

		return restomaticRef.child(aux).update(order).then((res) => {
      return {
        message: "Actualización exitosa",
        response: res
  }
    }, error => {
      return {
        message: "Actualización fallída",
        error: error
       }
    });
  }

  updateTableDB = (id, table) => {
    const aux = id && id.replace('ORDERID', '')
		const restomaticRef = this.ref.child("tables");

		return restomaticRef.child(aux).update(table).then((res) => {
      return {
        message: "Actualización exitosa",
        response: res
  }
    }, error => {
      return {
        message: "Actualización fallída",
        error: error
       }
    });
  }

  updateConfigRoom = (id, order) => {
		const restomaticRef = this.ref.child("ConfigDisplayBD");

		return restomaticRef.child(id).update(order).then((res) => {
      return {
        message: "Registro exitoso",
        response: res
  }
    }, error => {
      return {
        message: "Registro fallído",
        error: error
       }
    });
  }
  registerTables = (obj) => {
    if (this.ref) {
      const restomaticRef = this.ref.child("tables");
      return restomaticRef.push(obj)
    }
  }
}

export default RestomaticOnlineServices;
