import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Dropdown, FormControl, InputGroup, Form } from "react-bootstrap";
import { Table, Button, Card, Modal, Select, InputNumber, Spin, Checkbox  } from 'antd';
import Webcam from 'react-webcam'

import TimeAgo from 'react-timeago'
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { Utils } from "../../../utils/utils";
import {PAY_TYPES, REASONS_RETURN_ORDER, STATES_ORDER, REASONS_CANCEL_ORDER} from "../../../constants";
import RestomaticOnlineServices from "../../../services/services";
const moment = require('moment-timezone');

const { Option } = Select;
const formatter = buildFormatter(spanishStrings)

const OrderList = ({ reference, getAllOrdersRef, storage, getConfigDisplayRef, getAllTablesRef }) => {
  const webcamRef = React.useRef(null);
  // useEffect(() => {
  //   if (allOrdersBD.length === 0) console.log("existen ordenes")
  // })
  const worker = localStorage.getItem("workerCod") || ""
  const [isImageUploaded, setIsImageUploaded] = useState(false)
  const [configLoaded, setConfigLoaded] = useState(null)
  const [clientPayTypeSelected, setClientPayTypeSelected] = useState(null)
  const [urlInvoiceAdded, setUrlInvoiceAdded] = useState(null)
  const [orderSelected, setOrderSelected] = useState({})
  const [data, setData] = useState(
    document.querySelectorAll("#order_list tbody tr")
  );
  const sort = 5;
  const activePag = useRef(0);
  const [onlyOrdersPayed, setOnlyOrdersPayed] = useState([])
  const [test, settest] = useState(0);
  const [allOrdersBD, setAllOrdersBD] = useState([])
  const [reasonReturnSelected, setReasonReturnSelected] = useState(null)
  const [reasonCancelSelected, setReasonCancelSelected] = useState(null)
  const [showModalPay, setShowModalPay] = useState(false)
  const [showModalCancelOrder, setShowModalCancelOrder] = useState(false)
  const [showModalReturnOrder, setShowModalReturnOrder] = useState(false)
  const [typePayLoaded, setTypePayLoaded] = useState("")
  const [amountForPay, setAmountForPay] = useState(null)
  const [amountForPayCustomize, setAmountForPayCustomize] = useState(null)
  const [customizePaySelected, setSetcustomizePaySelected] = useState(false)
  const [listAllPays, setListAllPays] = useState([])
  const [yapePayments, setYapePayments] = useState([])
  const [cashPayments, setCashPayments] = useState([])
  const [visaPayments, setVisaPayments] = useState([])
  const [showModalExpenses, setShowModalExpenses] = useState(false)
  const [showModalCashierInitial, setShowModalCashierInitial] = useState(false)
  const [ordersLoadedBD, setOrdersLoadedBD] = useState(false)
  const [imgSrc, setImgSrc] = useState(null);
  const [allTablesBD, setAllTablesBD] = useState([])
  const [showTakePhoto, setShowTakePhoto] = useState(false)


  const filterIsBetweenToday = (date) => {
    let result = false;
    const todayDate = moment.tz(new Date(), "America/Lima").format()
    // console.log(todayDate)
    let initDay = todayDate.split("T")
    let finalDay = todayDate.split("T")
    initDay = initDay[0]+"T00:00:00-05:00"
    finalDay = finalDay[0]+"T23:59:59-05:00"
    initDay = Date.parse(initDay)
    finalDay = Date.parse(finalDay)
    date = Date.parse(date)
    console.log(initDay)
    console.log(finalDay)
    console.log(date)
    // if (moment.tz(Date.parse(date).isAfter(Date.parse(initDay)))) result = true
    if (initDay <= date && date <= finalDay) result = true
    return result
  }

  const addNewPay = () => {
    let aux = [ ...listAllPays ]
    const obj = {
      payType: null,
      statePay: null,
      urlInvoice: null
    }
    aux.push(obj)
    setListAllPays(aux)
  }
  const getConfigUpdated = () => {
    let allOrdersAux = []
    let allIds = []
    getConfigDisplayRef.on("value", (snapshot) => {
      let result = []
      snapshot.forEach(e => {
        allIds.push(e.key)
      })
         if (snapshot.val() !== null) {
           allOrdersAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
           console.log(allOrdersAux)
           allOrdersAux = Object.values(allOrdersAux)
           allOrdersAux = allOrdersAux.map((e, index) => {
            console.log(allIds)
           if (allIds.length) {
           allIds.forEach(k => {
             e.keyBD = `${allIds[index]}`
           })
           }
           return e
        })
           console.log(allOrdersAux)
          if (allOrdersAux && allOrdersAux[0]) setConfigLoaded(allOrdersAux[0])
          console.log(allOrdersAux[0])
          
         }
         return;
    })
  }
  const getAllTablesFirebase = () => {
    let allIds = []
    let allOrdersAux = []
    getAllTablesRef.on("value", (snapshot) => {
         if (snapshot.val() !== null) {
          snapshot.forEach(e => {
            allIds.push(e.key)
          })
           allOrdersAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
           allOrdersAux = Object.values(allOrdersAux)
           allOrdersAux = allOrdersAux.map((e, index) => {
              console.log(allIds)
             if (allIds.length) {
             allIds.forEach(k => {
               e.keyBD = `ORDERID${allIds[index]}`
             })
             }
             return e
          })
          setAllTablesBD(allOrdersAux)
           console.log(allOrdersAux, "tablesBD")
         }
         return;
       }, (error) => {
         console.log("ERROR: " + error.code);
       });
    }
    if (allTablesBD && allTablesBD.length === 0) getAllTablesFirebase()
  console.log(configLoaded)
  if (configLoaded === null) getConfigUpdated()
  const getAllOrdersFirebase = () => {
    let allIds = []
    let allOrdersAux = []
    getAllOrdersRef.on("value", (snapshot) => {
         if (snapshot.val() !== null) {
          snapshot.forEach(e => {
            allIds.push(e.key)
          })
           allOrdersAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
           allOrdersAux = Object.values(allOrdersAux)
           allOrdersAux = allOrdersAux.map((e, index) => {
             console.log(allIds)
            if (allIds.length) {
            allIds.forEach(k => {
              if (!e.keyBD) e.keyBD = `ORDERID${allIds[index]}`
            })
            }
            return e
          })
          let resultFilterBydate = []
          let reverseList = []
          console.log(allOrdersAux)
          if (allOrdersAux) {
            allOrdersAux.forEach(k => {
              if (k && filterIsBetweenToday(k.dateRegistered)) resultFilterBydate.push(k)
            })
          }
          console.log(resultFilterBydate, "resultFilterBydate")
          setOrdersLoadedBD(true)
          reverseList = [...resultFilterBydate].reverse()
          setAllOrdersBD(reverseList)
         }
         return;
       }, (error) => {
         console.log("ERROR: " + error.code);
       });
    }
    if (allOrdersBD.length === 0 && !ordersLoadedBD) getAllOrdersFirebase()
  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#order_list tbody tr"));
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  const getNameStateByCode = (state) => {
    let result = ""
    if (state) {
      STATES_ORDER.forEach(e => {
        if (e.value === state) result = e.label
      })
    }
    return result;   
  }
  const payOrder = (order) => {
    console.log("pagar")
    setOrderSelected(order)
    setShowModalPay(true)
  }
  const cancelOrder = (order) => {
    console.log("cancelar")
    setOrderSelected(order)
    setShowModalCancelOrder(true)
  }
  const closeModalCancelOrder = () => {
    setShowModalCancelOrder(false)
  }
  const closeModalReturnOrder = () => {
    setShowModalReturnOrder(false)
  }
  const returnOrder = (order) => {
    console.log("retornar", order)
    setOrderSelected(order)
    setShowModalReturnOrder(true)
  }
  const handleReasonReturn = (value) => {
    if (value) {
      setReasonReturnSelected(value)
    }
  }
  const handleReasonCancel = (value) => {
    if (value) {
      setReasonCancelSelected(value)
    }
  }
  const closeModalPay = () => {
    setShowModalPay(false)
    setOrderSelected({})
    setUrlInvoiceAdded(null)
    setClientPayTypeSelected(null)
  }
  const confirmReturnOrder = () => {
    let auxFinalOrder = {...orderSelected}
    const service = new RestomaticOnlineServices(reference);

    if (reasonReturnSelected) {;
      auxFinalOrder.returnInformation = {}
      auxFinalOrder.returnInformation.date = moment.tz(new Date(), "America/Lima").format()
      auxFinalOrder.returnInformation.reason = reasonReturnSelected || null
      auxFinalOrder.returnInformation.cashierAssigned = worker || null
      auxFinalOrder.returnInformation.state = 6

    service.updateOrderDB(auxFinalOrder.keyBD, auxFinalOrder).then(res => {
      closeModalReturnOrder()
      setReasonReturnSelected(null)
      getAllOrdersFirebase()
      })
    }
  }
  const confirmCancelOrder = () => {
    let auxFinalOrder = {...orderSelected}
    const service = new RestomaticOnlineServices(reference);

    if (reasonCancelSelected) {      
      auxFinalOrder.state = 4;
      auxFinalOrder.cancelOrderInformation = {}
      auxFinalOrder.cancelOrderInformation.date = moment.tz(new Date(), "America/Lima").format()
      auxFinalOrder.cancelOrderInformation.reason = reasonCancelSelected || null
      auxFinalOrder.cancelOrderInformation.cashierAssigned = worker || null
      auxFinalOrder.cancelOrderInformation.state = 4

    service.updateOrderDB(auxFinalOrder.keyBD, auxFinalOrder).then(res => {
      closeModalCancelOrder()
      setReasonCancelSelected(null)
      })
    }
  }
  const confirmParcialPay = (index) => {
    let aux = [...listAllPays]
    if (aux && aux[index])
    aux[index].statePay = 3;
    setListAllPays(aux)
  }
  const confirmPay = () => {
    let auxFinalOrder = {...orderSelected}
    const service = new RestomaticOnlineServices(reference);
    // const serviceConfig = new RestomaticOnlineServices(reference);

      if ((auxFinalOrder && !auxFinalOrder.isDelivery && clientPayTypeSelected) || (auxFinalOrder && !auxFinalOrder.isDelivery && listAllPays)) {
        auxFinalOrder.paymentAtHomeInformation = {}
        auxFinalOrder.paymentAtHomeInformation.datePayment = moment.tz(new Date(), "America/Lima").format()
        auxFinalOrder.paymentAtHomeInformation.payType = clientPayTypeSelected
        auxFinalOrder.paymentAtHomeInformation.clientAmountForPay = amountForPay
        auxFinalOrder.paymentAtHomeInformation.clientAmountReturn = amountForPay && amountForPay - auxFinalOrder.totalAmount
        if (listAllPays && listAllPays.length) {
          auxFinalOrder.paymentAtHomeInformation.allInvoices = listAllPays
        }
        else auxFinalOrder.paymentAtHomeInformation.invoiceBillUrl = urlInvoiceAdded || null;
        auxFinalOrder.paymentAtHomeInformation.cashierAssigned = worker
        auxFinalOrder.state = 3
      } else {
        if (auxFinalOrder && auxFinalOrder.deliveryInformation) {
          auxFinalOrder.state = 3
          auxFinalOrder.deliveryInformation.datePayment = moment.tz(new Date(), "America/Lima").format()
          if (listAllPays && listAllPays.length) {
            auxFinalOrder.deliveryInformation.allInvoices = listAllPays
          }
          else auxFinalOrder.deliveryInformation.invoiceBillUrl = urlInvoiceAdded || "";
          auxFinalOrder.deliveryInformation.cashierAssigned = worker
        }        
      }
    // }
    console.log(auxFinalOrder)

    service.updateOrderDB(auxFinalOrder.keyBD, auxFinalOrder).then(res => {
      if (auxFinalOrder && auxFinalOrder.deliveryInformation && auxFinalOrder.deliveryInformation.infoClientFromRoom) {
        let configLoadedAux = { ...configLoaded }
        configLoadedAux.updated = true
        setTimeout(() => {
          service.updateConfigRoom(configLoadedAux.keyBD, configLoadedAux).then(res => {
          console.log("SE REGISTRO EN SALA")
        }, error => {
          console.log("NO SE REGISTRO EN SALA")
        })
        }, 800)
      }
      if (auxFinalOrder && auxFinalOrder.tableId && !auxFinalOrder.isDelivery && allTablesBD) {
        let tableSelectedUI = {}
        allTablesBD.forEach(k => {
          if (k.tableId === auxFinalOrder.tableId) tableSelectedUI = k
        })
        if (tableSelectedUI.state === 2) {
          tableSelectedUI.state = 1
          service.updateTableDB(tableSelectedUI.keyBD, tableSelectedUI).then(res => console.log('Actualizado'))
        }
      }
      closeModalPay()
      getAllOrdersFirebase()
      setUrlInvoiceAdded(null)
      setIsImageUploaded(false)
      setListAllPays([])
      setSetcustomizePaySelected(false)
    })
  }
  const calculateByPayType = (orders, payType) => {
    let totalAmount = 0
    let onlyAmounts = []
    let auxList = []

    if (orders && payType) {
      orders.forEach(el => {
        if ((el.state === 3 && el.deliveryInformation && el.deliveryInformation.payType === payType && filterIsBetweenToday(el.dateRegistered)) || (el.state === 3 && el.paymentAtHomeInformation && el.paymentAtHomeInformation.payType === payType && filterIsBetweenToday(el.dateRegistered))) {
          auxList.push(el)
        }
      })
    }
    console.log(auxList)
    if (auxList) {
      onlyAmounts = auxList.map(e => e.totalAmount)
      if (onlyAmounts.length) totalAmount = onlyAmounts.reduce((a,b) => a + b)
      console.log(totalAmount)

    }
    return totalAmount
  }
  const calculateTotalAmount = (auxList) => {
    let totalAmount = 0
    let onlyAmounts = []
    let result = 0
    console.log(auxList)
    let auxValidateFinalOrdes = []
    let auxYapePayments = []
    let auxCashPayments = []
    let auxVisaPayments = []
    auxList.forEach(k => {
      if ((k.state === 3 && filterIsBetweenToday(k.dateRegistered)) || (k && k.deliveryInformation && k.deliveryInformation.state === 3) && filterIsBetweenToday(k.dateRegistered)) auxValidateFinalOrdes.push(k)
    })
    console.log(auxValidateFinalOrdes)
    if (auxValidateFinalOrdes.length) {
      //setOnlyOrdersPayed(auxValidateFinalOrdes)
        onlyAmounts = auxValidateFinalOrdes.map(e =>  {
          if (e.state === 3 && filterIsBetweenToday(e.dateRegistered)) {
            result = e.totalAmount
          }
          return result
        })
        console.log(onlyAmounts)
        totalAmount = onlyAmounts.reduce((a,b) => a + b)
        console.log(totalAmount)
        // auxValidateFinalOrdes.forEach(el => {
        //   if ((el.deliveryInformation && el.deliveryInformation.payType === 1) || (el.paymentAtHomeInformation && el.paymentAtHomeInformation.payType === 1)) auxCashPayments.push(el)
        //   if ((el.deliveryInformation && el.deliveryInformation.payType === 2) || (el.paymentAtHomeInformation && el.paymentAtHomeInformation.payType === 2)) auxVisaPayments.push(el)
        //   if ((el.deliveryInformation && el.deliveryInformation.payType === 4) || (el.paymentAtHomeInformation && el.paymentAtHomeInformation.payType === 4)) auxYapePayments.push(el)
        // })
      }
      // if (auxCashPayments.length === 0) setCashPayments(auxCashPayments)
      // if (auxVisaPayments.length === 0) setVisaPayments(auxVisaPayments)
      // if (auxYapePayments.length === 0) setYapePayments(auxYapePayments)
      return totalAmount;
    }
    console.log(onlyOrdersPayed)
    // console.log(visaPayments)
    // console.log(yapePayments)

    console.log(allOrdersBD)
    let reverseOrders = []
    // reverseOrders = allOrdersBD.reverse()
    console.log(reverseOrders)
    const reverseArr = () => {
      for(var i = allOrdersBD.length-1; i >= 0; i--) {
        const parseDate = allOrdersBD[i] && new Date(allOrdersBD[i].dateRegistered)
        if (((allOrdersBD[i] && allOrdersBD[i].state >= 2) || (allOrdersBD[i] && allOrdersBD[i].deliveryInformation && allOrdersBD[i].deliveryInformation.state >=2)) && 
        (filterIsBetweenToday(allOrdersBD[i].dateRegistered))) {
          reverseOrders.push(allOrdersBD[i]);
         }
      }
    }
    
    // if (allOrdersBD && reverseOrders.length === 0) reverseArr()
    console.log(reverseOrders)
    
    console.log(orderSelected)
    const getNamePayType = (id) => {
      let result = ""
      PAY_TYPES.forEach(e => {
        if (e.value === id) result = e.label
      })
      return result;
    }
    const downloadBill = () => {
       const bill = document.querySelectorAll("#bill-receipt")
       if (bill && bill.length > 0 && bill[0]) {
         console.log(bill[0])
   
         const aux = document.body.appendChild(document.createElement("a")
       );
       aux.download = "newfile.html";
       aux.href = "data:text/html," + bill[0].innerHTML;
       aux.click(); 
      
      // let mywindow = window.open('', 'PRINT', 'height=400,width=600');

      //   mywindow.document.write('</head><body >');
      //   mywindow.document.write(bill[0].innerHTML);
      //   mywindow.document.write('</body></html>');

      //   mywindow.document.close(); // necessary for IE >= 10
      //   mywindow.focus(); // necessary for IE >= 10*/

      //   // mywindow.print();
      //   // mywindow.close();
        
      //   return true;
      }
    }
    
    const handleChangePay = (value, position) => {
      if (value && position) {
        let aux = [...listAllPays]
        if (value !== 1) setAmountForPay(null)
        console.log(position)
        console.log(value)
        const index = aux.length-1
        if (position && position.value && aux[index]) {
          aux[index].payType = position.value
        }
        setListAllPays(aux)
      } else {
        if (value) {
          if (value !== 1) setAmountForPay(null)
          setClientPayTypeSelected(value)
        }
      }
    }

    const handleInfo = (evt, id) => {
      if (id === "amountForPay") {
        setAmountForPay(evt)
      }
    }
    const handleInfoCustomize = (evt, id) => {
      if (id === "amountForPayParcial") {
        setAmountForPayCustomize(evt)
      }
    }
    const uploadImage = (image, customize) => {
      console.log(image)
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        error => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then(url => {
             console.log(url)
             if (customize) {
               let aux = [...listAllPays]
               if (aux && aux[aux.length-1]) {
                aux[aux.length-1].urlInvoice = ""
                aux[aux.length-1].urlInvoice = url
                setListAllPays(aux)
               }
             }
             else setUrlInvoiceAdded(url)             
             setIsImageUploaded(false)
            });
        }
      );      
    }
    const handleChangeBillInvoice = (e) => {
        if (e && e.target) {
          let file = e.target.files[0]; // get the supplied file
          // if there is a file, set image to that file
          if (file) {
            setIsImageUploaded(true)
            console.log(file)
            uploadImage(file)
          }
      } 
    }
    const handleChangeBillInvoiceCustomize = (e) => {
        if (e && e.target) {
          let file = e.target.files[0]; // get the supplied file
          // if there is a file, set image to that file
          if (file) {
            setIsImageUploaded(true)
            console.log(file)
            uploadImage(file, true)
          }
      } 
    }
    const getReasonByCode = (id) => {
      let result = ""
      if (id) {
        REASONS_RETURN_ORDER.forEach(e => {
          if (e && e.cod === id) result = e.reason
        })
      }
      return result;
    }
    const handleCustomizePay = (e) => {
      console.log(`checked = ${e.target.checked}`);
      if (e){
        setSetcustomizePaySelected(e.target.checked)
      }
    }
    console.log(listAllPays)
    console.log(orderSelected)
    const showTakePhotoUI = () => {

    }
    const Base64ToImage = (base64img, callback) => {
      var img = new Image();
      // img.onload = function() {
      //     callback(img);
      // };
      img.src = base64img;
      return img
  }
    const capture = React.useCallback(() => {
      let image = new Image()
      console.log(image)
      const imageSrc = webcamRef.current.getScreenshot();
      console.log(imageSrc)
      const result = Base64ToImage(imageSrc)
      console.log(result)
      setImgSrc(imageSrc);
      uploadImage(imageSrc)
      setIsImageUploaded(true)
    }, [webcamRef, setImgSrc]);
  return (
    <Fragment>
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="">
          <h2 className="text-black font-w600 mb-0">Total de comandas de hoy son de #{allOrdersBD && allOrdersBD.length}</h2>
          <p className="mb-0">Aquí podrás visualizar el 100% de tus comandas de hoy y realizar el pago de estas</p>
        </div>
        {/* <Dropdown className="dropdown custom-dropdown">
          <Dropdown.Toggle
            variant=""
            className="i-false  btn btn-primary light d-flex align-items-center svg-btn"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <svg
              width={16}
              className="scale5"
              height={16}
              viewBox="0 0 22 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.16647 27.9558C9.25682 27.9856 9.34946 28.0001 9.44106 28.0001C9.71269 28.0001 9.97541 27.8732 10.1437 27.6467L21.5954 12.2248C21.7926 11.9594 21.8232 11.6055 21.6746 11.31C21.526 11.0146 21.2236 10.8282 20.893 10.8282H13.1053V0.874999C13.1053 0.495358 12.8606 0.15903 12.4993 0.042327C12.1381 -0.0743215 11.7428 0.0551786 11.5207 0.363124L0.397278 15.7849C0.205106 16.0514 0.178364 16.403 0.327989 16.6954C0.477614 16.9878 0.77845 17.1718 1.10696 17.1718H8.56622V27.125C8.56622 27.5024 8.80816 27.8373 9.16647 27.9558ZM2.81693 15.4218L11.3553 3.58389V11.7032C11.3553 12.1865 11.7471 12.5782 12.2303 12.5782H19.1533L10.3162 24.479V16.2968C10.3162 15.8136 9.92444 15.4218 9.44122 15.4218H2.81693Z"
                fill="#2F4CDD"
              />
            </svg>
            <span className="fs-16 ml-3">All Status</span>
            <i className="fa fa-angle-down scale5 ml-3" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="#">
              2020
            </a>
            <a className="dropdown-item" href="#">
              2019
            </a>
            <a className="dropdown-item" href="#">
              2018
            </a>
            <a className="dropdown-item" href="#">
              2017
            </a>
            <a className="dropdown-item" href="#">
              2016
            </a>
          </Dropdown.Menu>
        </Dropdown>
        */}
        <div className="row">
          <div className="col-12">
            {/* <span className="fs-16 ml-3"><b>TOTAL DE COMANDAS</b></span>
            <br /><br />
            <h6>(*) Aquí podrás cambiar al estado PAGADO las comandas correspondientes</h6> */}
            {/* <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text>Comanda</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl id="inlineFormInputGroup" placeholder="#" />
            </InputGroup> */}
          </div>
        </div>
        {/* <Dropdown className="dropdown custom-dropdown ml-3">
          <Dropdown.Toggle
            variant=""
            className="i-false  btn btn-primary light d-flex align-items-center svg-btn"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <svg
              width={16}
              height={16}
              className="scale5"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.4281 2.856H21.8681V1.428C21.8681 0.56 21.2801 0 20.4401 0C19.6001 0 19.0121 0.56 19.0121 1.428V2.856H9.71606V1.428C9.71606 0.56 9.15606 0 8.28806 0C7.42006 0 6.86006 0.56 6.86006 1.428V2.856H5.57206C2.85606 2.856 0.560059 5.152 0.560059 7.868V23.016C0.560059 25.732 2.85606 28.028 5.57206 28.028H22.4281C25.1441 28.028 27.4401 25.732 27.4401 23.016V7.868C27.4401 5.152 25.1441 2.856 22.4281 2.856ZM5.57206 5.712H22.4281C23.5761 5.712 24.5841 6.72 24.5841 7.868V9.856H3.41606V7.868C3.41606 6.72 4.42406 5.712 5.57206 5.712ZM22.4281 25.144H5.57206C4.42406 25.144 3.41606 24.136 3.41606 22.988V12.712H24.5561V22.988C24.5841 24.136 23.5761 25.144 22.4281 25.144Z"
                fill="#2F4CDD"
              />
            </svg>
            <span className="fs-16 ml-3">Hoy</span>
            <i className="fa fa-angle-down scale5 ml-3" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="#">
              Lunes
            </a>
            <a className="dropdown-item" href="#">
              Martes
            </a>
            <a className="dropdown-item" href="#">
              Miercoles
            </a>
            <a className="dropdown-item" href="#">
              Jueves
            </a>
            <a className="dropdown-item" href="#">
              Viernes
            </a>
            <a className="dropdown-item" href="#">
              Sabado
            </a>
            <a className="dropdown-item" href="#">
              Domingo
            </a>
          </Dropdown.Menu>
        </Dropdown>
      */}
      </div>
      <div className="row">
        
       <Button type="primary" onClick={() => null}>
                INGRESAR CAJA CHICA
            </Button>
            
       <Button type="primary" onClick={() => null}>
                GASTOS
            </Button>
            </div>
      <div className="row">
        <div className="col-12">
          <div className="table-responsive">
            <div id="order_list" className="dataTables_wrapper no-footer">
              <table
                id="example5"
                className="display mb-4 dataTablesCard dataTable no-footer w-100 "
                style={{ minWidth: 845 }}
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_asc"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label="Order ID: activate to sort column descending"
                      style={{ width: "71.3333px" }}
                    >
                      #
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date: activate to sort column ascending"
                      style={{ width: "74.6667px" }}
                    >
                      Fecha de consumo
                    </th>
                    {/* <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Customer Name: activate to sort column ascending"
                      style={{ width: "85.3333px" }}
                    >
                      Customer Name
                    </th> */}
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      style={{ width: "98.6667px" }}
                    >
                      Tipo de atención
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      style={{ width: 68 }}
                    >
                      Total
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      style={{ width: "89.3333px" }}
                    >
                      Estado
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label=": activate to sort column ascending"
                      style={{ width: 24 }}
                    />
                  </tr>
                </thead>
                <tbody>
                  {
                    allOrdersBD ? allOrdersBD.map(el =>{
                      if (el && el.totalAmount && el.state > 1)
                    return (
                  <tr role="row" className="odd">
                    <td className="sorting_1">{el.orderNumber || ""}</td>
                    <td>
                      {
                        el.dateRegistered &&
                        <TimeAgo date={el.dateRegistered} formatter={formatter} />
                      }
                    </td>
                    {/* <td>Roberto Carlo</td> */}
                    <td>{(el.tableId && `MESA ${el.tableId}`) || "PARA LLEVAR"}</td>
                    <td>{el.totalAmount || ""} {el.totalAmount && "soles"}</td>
                    <td>
                      <span className={`btn btn-sm ${el.state !== 3 && "light"} btn-${el.state === 3 ? "info" : "warning"}`}>
                        {
                          (el.deliveryInformation && el.deliveryInformation.state && el.state !== 3) ?
                          (el &&el.cancelOrderInformation && el.state === 4) ? "Cancelado" : `${getNameStateByCode(el.deliveryInformation.state)} ${el.state === 3 ? "-" : ""} ${el.state === 3 ? getNameStateByCode(el.state) : ""}`
                          :
                          getNameStateByCode(el.state)
                        }
                        {
                          (el && el.returnInformation) && "- Devolución"
                        }
                      </span>
                    </td>
                    <td>
                      <Dropdown className="dropdown ml-auto text-right">
                        <Dropdown.Toggle
                          variant=""
                          className="i-false p-0 btn-link"
                          data-toggle="dropdown"
                        >
                          <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <circle fill="#000000" cx={5} cy={12} r={2} />
                              <circle fill="#000000" cx={12} cy={12} r={2} />
                              <circle fill="#000000" cx={19} cy={12} r={2} />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                          <a onClick={() => payOrder(el)} className="dropdown-item">
                            <i className="las la-check-square scale5 text-primary mr-2" />{" "}
                            {((el && el.state === 3) || (el && el.deliveryInformation && el.deliveryInformation.state === 3) || (el && el.state === 4 && el.cancelOrderInformation)) ? "VER" : "PAGAR"}
                          </a>
                          {
                            (worker && (worker === "admn01" || worker === "admn02")) &&
                          <a  onClick={() => cancelOrder(el)} className="dropdown-item">
                            <i className="las la-times-circle scale5 text-danger mr-2" />{" "}
                            {el && el.cancelOrderInformation ? "VER CANCELACION" : "CANCELAR"}
                          </a>
                          }
                          {
                              (el && (el.state === 3 || el.state > 6)) &&
                          <a  onClick={() => returnOrder(el)} className="dropdown-item">
                            <i className="las la-times-circle scale5 text-danger mr-2" />{" "}
                            {(el && el.returnInformation) ? "VER DEVOLUCION" : "DEVOLVER"}
                          </a>
                          }
                        </Dropdown.Menu>
                     
                      </Dropdown>
                    </td>
                  </tr>
                    )  
                  })
                  : null
                  }
                </tbody>
              </table>
              <div className="d-sm-flex text-center justify-content-between">
                <div className="dataTables_info" style={{textAlign: "left"}}>
                {allOrdersBD && <h4 className="text-blue">TOTAL EN EL DIA: S/ {calculateTotalAmount(allOrdersBD)}</h4>}
                {allOrdersBD && <h4 className="text-red">TOTAL EN YAPE: S/ {calculateByPayType(allOrdersBD, 4)}</h4>}
                {allOrdersBD && <h4 className="text-red">TOTAL EN EFECTIVO: S/ {calculateByPayType(allOrdersBD, 1)}</h4>}
                {allOrdersBD && <h4 className="text-red">TOTAL EN VISA: S/ {calculateByPayType(allOrdersBD, 2)}</h4>}
                  {/* Mostrando del {activePag.current * sort + 1} al{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  de {data.length} pedidos */}
                </div>
                {/* <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/order-list"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                  >
                    Previous
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="/order-list"
                        className={`paginate_button  ${
                          activePag.current === i ? "current" : ""
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/order-list"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    Next
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
       <Modal title={orderSelected.state === 3 ? "COMANDA YA SE ENCUENTRA PAGADA" : "CONFIRMACION DE PAGO"} visible={showModalPay} onOk={() => closeModalPay()} onCancel={() => closeModalPay()}
       style={{ top: 20}} className="modal-pay" okText="CERRAR" cancelButtonProps={{hidden: true}}>
           <div>
             {/* <p>Desea confirmar el pago de la comanda #{orderSelected.orderNumber}</p> */}
             <Card id="bill-receipt" style={{ width: 300, margin: "auto", textAlign: "center"}}>
                      <p style={{textAlign: "center", fontSize: "0.7em"}}>
                        EL REY DEL SABOR <br />
                        RUC: 28283829<br />
                        URBANIZACION SAGITARIO-SANTIAGO DE SURCO<br />
                        LIMA-LIMA-SANTIAGO DE SURCO<br />
                        TELEF: 388844<br />
                        LOCAL: {(orderSelected && orderSelected.deliveryInformation && orderSelected.deliveryInformation.fromLocal && orderSelected.deliveryInformation.fromLocal.replace("local-",""))
                        || (orderSelected && orderSelected.fromLocal && orderSelected.fromLocal.replace("local-","")) || ""
                        }<br />
                        BOLETA DE VENTA ELECTRONICA<br />
                        B-{orderSelected && orderSelected.orderNumber}
                      <div>
                      <br />
                        <div className="row">
                            <div className="col-8">
                              FECHA Y HORA DE CONSUMO: {orderSelected.dateRegistered && orderSelected.dateRegistered.replace("-05:00", "")}
                            </div>
                            <div className="col-4">
                              MESA: {orderSelected.isDelivery && "PARA LLEVAR"} {orderSelected.tableId ? orderSelected.tableId : ""}
                            </div>
                          </div>
                          <div className="row" style={{margin: "auto"}}>----------------------------------------------</div>
                        <div className="row">
                            <div className="col-4">
                              ITEM
                            </div>
                            <div className="col-4">
                              CANTIDAD
                            </div>
                            <div className="col-4">
                              TOTAL
                            </div>
                        </div>
                        {
                          (orderSelected && orderSelected.products) && orderSelected.products.map(e => {
                            return (
                              <div className="row">
                                <div className="col-6">
                                    {e.description}
                                  </div>
                                  <div className="col-2">
                                    {e.quantity}
                                  </div>
                                  <div className="col-4">
                                    {e.amount}
                                  </div>
                              </div>
                            )
                          })
                        }
                        {
                          ((orderSelected && orderSelected.deliveryInformation && orderSelected.deliveryInformation.address) ||
                          (orderSelected && orderSelected.deliveryInformation && orderSelected.withDeliveryFromLocal)) &&
                          <div className="row">
                            <div className="col-6">
                                ENVIO
                              </div>
                              <div className="col-2">
                                -
                              </div>
                              <div className="col-4">
                                3
                              </div>
                          </div>
                        }                  
                        <div className="row" style={{margin: "auto"}}>----------------------------------------------</div>
                          <div className="row">
                              <div className="col-7">
                                  TOTAL A PAGAR
                                </div>
                                <div className="col-2">
                                  S/
                                </div>
                                <div className="col-3">
                                {orderSelected.totalAmount && orderSelected.totalAmount}
                                </div>
                            </div>
                            <div className="row">
                              <div className="col-7">
                                  CLIENTE PAGA CON
                                </div>
                                <div className="col-2">
                                  S/
                                </div>
                                <div className="col-3">
                                {orderSelected.deliveryInformation && orderSelected.deliveryInformation.clientPayAmount}
                                </div>
                            </div>
                            <div className="row">
                              <div className="col-7">
                                  VUELTO
                                </div>
                                <div className="col-2">
                                  S/
                                </div>
                                <div className="col-3">
                                  {
                                    (orderSelected && orderSelected.deliveryInformation && orderSelected.deliveryInformation.payType === 1) &&
                                    ((orderSelected.deliveryInformation && orderSelected.deliveryInformation.clientPayAmount) ?
                                    orderSelected.deliveryInformation.clientPayAmount - orderSelected.totalAmount
                                    :
                                    orderSelected.deliveryInformation && orderSelected.deliveryInformation.amountReturn)
                                  }                                
                                </div>
                            </div>
                            <div className="row">
                              <div className="col-8">
                                  METODO DE PAGO
                                </div>
                                <div className="col-4">
                                  {(orderSelected && orderSelected.paymentAtHomeInformation && orderSelected.paymentAtHomeInformation.payType)
                                  ?
                                  getNamePayType(orderSelected.paymentAtHomeInformation.payType)
                                :
                                orderSelected.deliveryInformation && getNamePayType(orderSelected.deliveryInformation.payType)
                                }
                                </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                  DIRECCION DE ENVIO
                                </div>
                                <div className="col-6">
                                  {orderSelected.deliveryInformation && orderSelected.deliveryInformation.address}
                                </div>
                            </div>
                        </div>
                      </p>
                      {/* {(orderSelected && orderSelected.state !== 3) && <Checkbox onChange={(e) => handleCustomizePay(e)}>Pago personalizado</Checkbox>} */}
                      {customizePaySelected && <br/>}
                      {customizePaySelected && <br/>}
                      {
                        customizePaySelected &&
                         <Button type="primary" onClick={() => addNewPay()}
                         danger>
                           + TIPO DE PAGO
                       </Button>
                      }
                      {customizePaySelected && <br/>}
                      {customizePaySelected && <br/>}
                      {
                        customizePaySelected ? 
                    <div className="widget-timeline-icon">
                      <ul className="timeline">
                        {
                          listAllPays && listAllPays.map((e, index) => {
                            console.log(index)
                            // if ()
                        return (
                        <li>
                          <div className="icon bg-primary" />
                          <a className="timeline-panel text-muted">
                            <h4 className="mb-2">Tipo de pago:</h4>
                            <p className="fs-15 mb-0 ">
                            <Select defaultValue={listAllPays && listAllPays[listAllPays.length-1] && listAllPays[listAllPays.length-1].payType} style={{ width: 120 }} onChange={(e, index) => handleChangePay(e, index)}>
                              {
                                PAY_TYPES && PAY_TYPES.map(e => {
                                  return <Option value={e.value}>{e.label}</Option>
                                })
                              }
                            </Select>
                            </p>
                            {
                              
                              (listAllPays && listAllPays[index] && listAllPays[index].payType !== 1 && listAllPays[index].payType !== null && !listAllPays[index].statePay && listAllPays[index].statePay !== 3 &&
                                !listAllPays[index].statePay && listAllPays[index].statePay !== 3) &&
                              <>
                              <p>Adjunta la constancia de pago</p>
                              <input type="file" className="input-product-img" onChange={(evt) => handleChangeBillInvoiceCustomize(evt)} accept="image/x-png,image/jpeg" style={{marginBottom: "20px"}} />
                              </>
                          }
                          {
                          (listAllPays && listAllPays[index] && listAllPays[index].payType === 1 && listAllPays[index].payType !== null
                            //  && !listAllPays[index].statePay && listAllPays[index].statePay !== 3 && !listAllPays[index].statePay && listAllPays[index].statePay !== 3
                             )
                              &&
                            <>
                            <h6>Cliente paga con: <InputNumber onChange={(evt) => handleInfoCustomize(evt, "amountForPayParcial")} value={amountForPayCustomize} min={0}/></h6>
                            {/* <h6>Vuelto para cliente es de: {(amountForPayCustomize > 0 && orderSelected.totalAmount > 0) ? amountForPayCustomize - orderSelected.totalAmount : 0}</h6> */}
                            </>
                          }
                            {
                              (listAllPays && listAllPays[index] && listAllPays[index].urlInvoice) &&
                              <img src={listAllPays[index].urlInvoice} className="product-loaded" style={{maxWidth: "100px"}} />
                            }                            
                            {
                              (listAllPays && listAllPays.length > 0 && listAllPays[index] && !listAllPays[index].statePay && listAllPays[index].statePay !== 3) &&
                              <Button type="primary" onClick={() => confirmParcialPay(index)} disabled={(listAllPays[index].payType !== 1 && !listAllPays[index].urlInvoice) ||
                               (listAllPays && listAllPays[index] && listAllPays[index].payType === 1 && !amountForPayCustomize)} danger>
                                  CONFIRMAR PAGO PARCIAL
                              </Button>
                            }
                          </a>
                        </li>
                        )
                          })
                        }
                        <br />
                        {
                          (listAllPays && listAllPays.length > 0) &&
                          <>
                          (*) CONFIRMA TODOS LOS PAGOS PARCIALES PARA CONFIRMAR EL PAGO TOTAL
                          <Button type="primary" onClick={() => confirmPay()} danger>
                                CONFIRMAR PAGO TOTAL
                            </Button>
                          </>
                        }
                      </ul>
                    </div>
                        :
                        (orderSelected.isDelivery === false && orderSelected.state !== 3) &&
                        <div style={{display: "inline-flex"}}>
                          <h5>Tipo de pago: </h5>
                        <Select defaultValue={clientPayTypeSelected} style={{ width: 120 }} onChange={(e) => handleChangePay(e)}>
                          {
                            PAY_TYPES && PAY_TYPES.map(e => {
                              return <Option value={e.value}>{e.label}</Option>
                            })
                          }
                        </Select>
                        </div>
                      }
                      {(!orderSelected.isDelivery && clientPayTypeSelected === 1) && <h6>Cliente paga con: <InputNumber onChange={(evt) => handleInfo(evt, "amountForPay")} value={amountForPay} min={0}/></h6>}
                      {(!orderSelected.isDelivery && clientPayTypeSelected === 1) && <h6>Vuelto para cliente es de: {(amountForPay > 0 && orderSelected.totalAmount > 0) ? amountForPay - orderSelected.totalAmount : 0}</h6>}
                      {((orderSelected.state !== 3) || (orderSelected.state !== 4)) && <><br /><br /></>}
                      
                        {
                          // {/* <Button type="primary" onClick={() => showTakePhotoUI()} danger>
                          // MOSTRAR TOMAR FOTO
                          // </Button> */}
                          (clientPayTypeSelected && (clientPayTypeSelected === 4 
                             || (orderSelected && orderSelected.deliveryInformation && orderSelected.deliveryInformation.payType ===  4)
                          )) &&
                          <>
                          <Webcam
                          audio={false}
                          height={180}
                          width={180}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          />
                          <button onClick={capture}>TOMAR FOTO</button>
                          {imgSrc && (
                            <img
                              src={imgSrc}
                            />                            
                          )}                          
                        </>
                      }
                      {
                        ((orderSelected && orderSelected.state !== 3 && !orderSelected.cancelOrderInformation) || (orderSelected && orderSelected.state !== 4)) ?
                        <>
                        {
                            ((orderSelected && orderSelected.isDelivery && !orderSelected.tabledId && orderSelected.deliveryInformation && orderSelected.deliveryInformation.payType !== 1)
                            || (orderSelected && !orderSelected.isDelivery && orderSelected.state !== 3))
                            ?
                              ((orderSelected && orderSelected.state !== 3 && clientPayTypeSelected !== 1 && clientPayTypeSelected !== null)
                              || (orderSelected && orderSelected.deliveryInformation && orderSelected.deliveryInformation.payType )
                              || (orderSelected && !orderSelected.isDelivery && orderSelected.state !== 3 && clientPayTypeSelected !== null && clientPayTypeSelected !== 1)
                              )
                              &&
                                <>
                                {
                                  (orderSelected && orderSelected.state === 3 && orderSelected.deliveryInformation && orderSelected.deliveryInformation.datePayment
                                    && !orderSelected.cancelOrderInformation)
                                  ?
                                  <h5 className="text-red">YA SE ENCUENTRA PAGADA</h5>
                                  :
                                  (orderSelected && orderSelected.state === 3 && orderSelected.deliveryInformation && orderSelected.deliveryInformation.datePayment
                                    && orderSelected.returnInformation )
                                  ?
                                  <h5>YA SE ENCUENTRA PAGADA CON DEVOLUCIÓN</h5>
                                  :
                                  !customizePaySelected &&
                                  <>
                                  <p>Adjunta la constancia de pago</p>
                                  <input type="file" className="input-product-img" onChange={(e) => handleChangeBillInvoice(e)} accept="image/x-png,image/jpeg" style={{marginBottom: "20px"}} />
                                  </>
                                }
                                 </>
                            :
                              (orderSelected && orderSelected.state !== 4 && !orderSelected.isDelivery && orderSelected.deliveryInformation && orderSelected.deliveryInformation.payType === 1)
                                &&
                              <Button type="primary" onClick={() => confirmPay()}
                                disabled={(orderSelected && !orderSelected.isDelivery && !clientPayTypeSelected)}
                                danger>
                                  CONFIRMAR PAGO CON EFECTIVO 1
                              </Button>
                          }
                          {
                            (orderSelected && orderSelected.state !== 4 && orderSelected.deliveryInformation && orderSelected.deliveryInformation.datePayment && orderSelected.returnInformation) &&
                            <h5>YA SE ENCUENTRA PAGADA CON DEVOLUCIÓN</h5>
                          }
                          {
                            ((orderSelected && orderSelected.state === 3 && orderSelected.paymentAtHomeInformation && orderSelected.paymentAtHomeInformation.invoiceBillUrl && !orderSelected.returnInformation && !orderSelected.isDeliver) ||
                            (orderSelected && orderSelected.state === 3 && orderSelected.deliveryInformation && orderSelected.deliveryInformation.datePayment && !orderSelected.returnInformation && !orderSelected.isDelivery)
                            ) &&
                            <>
                              <h5>YA SE ENCUENTRA PAGADdA</h5> <br />
                              {
                                ((orderSelected && orderSelected.deliveryInformation && orderSelected.deliveryInformation.payType !== 1) ||
                                (orderSelected && orderSelected.paymentAtHomeInformation && orderSelected.paymentAtHomeInformation.payType !== 1)
                                ) &&
                                <a target="_blank" href={(orderSelected && orderSelected.paymentAtHomeInformation && orderSelected.paymentAtHomeInformation.invoiceBillUrl) ||
                                 (orderSelected && orderSelected.deliveryInformation && orderSelected.deliveryInformation.invoiceBillUrl)} download>
                               VER COMPROBANTE PAGO
                             </a>
                            }
                            </>
                          }
                          <div>
                            {(orderSelected && orderSelected.state === 3 && orderSelected.deliveryInformation && orderSelected.deliveryInformation.allInvoices) && <h5>Listado de comprobantes:</h5>}
                            <ul>
                            {
                              (orderSelected && orderSelected.state === 3 && orderSelected.deliveryInformation && orderSelected.deliveryInformation.allInvoices) &&
                              orderSelected.deliveryInformation.allInvoices.map(e => {
                                return (
                                  <li>
                                    <a target="_blank" href={(e && e.urlInvoice) || ""} download>
                                  VER COMPROBANTE PAGO
                                </a>
                                </li>
                                )
                              })
                            }
                          </ul>
                          </div>
                           {
                              (orderSelected && orderSelected.state === 3 && orderSelected.isDelivery && orderSelected.deliveryInformation &&
                                orderSelected.deliveryInformation.payType !== 1 && orderSelected.deliveryInformation.invoiceBillUrl && !orderSelected.returnInformation && !orderSelected.cancelOrderInformation
                                ) &&
                              <a target="_blank" href={(orderSelected.deliveryInformation.invoiceBillUrl) || ""} download>
                                VER COMPROBANTE PAGO
                              </a>
                            }
                            {
                              (orderSelected && orderSelected.state === 3 && orderSelected.isDelivery && orderSelected.deliveryInformation && orderSelected.deliveryInformation.payType === 1 && 
                                orderSelected.deliveryInformation.datePayment && !orderSelected.returnInformation && !orderSelected.cancelOrderInformation
                                ) &&
                              <h5>YA SE ENCUENTRA PAGADdA</h5>
                            }
                            {
                            (orderSelected && orderSelected.state === 3 && !orderSelected.isDelivery && orderSelected.paymentAtHomeInformation && orderSelected.paymentAtHomeInformation.allInvoices) &&
                            <>
                               <h5>YA SE ENCUENTRA PAGADdA</h5>
                               <ul>
                               {
                                 orderSelected.paymentAtHomeInformation.allInvoices && orderSelected.paymentAtHomeInformation.allInvoices.map((k, index) => {
                                   return (
                                    <li>
                                      <a target="_blank" href={(k.urlInvoice) || ""} download>
                                    VER COMPROBANTE PAGO #{index+1}
                                  </a>
                                  </li>
                                   )
                                 })
                               }
                               </ul>
                            </>
                          }
                          {
                            (orderSelected && orderSelected.state !== 3 && orderSelected.state !== 4 && !orderSelected.returnInformation && !orderSelected.cancelOrderInformation && orderSelected.isDelivery &&
                              !orderSelected.deliveryInformation
                              ) &&
                            <Button type="primary" onClick={() => confirmPay()} disabled={(clientPayTypeSelected === 1 && !amountForPay) || (orderSelected.deliveryInformation && orderSelected.deliveryInformation.payType !== 1)} danger>
                              CONFIRMAR PAGO 5
                          </Button>
                          }
                          {
                            isImageUploaded &&
                            <Spin tip="Cargando..."
                            style={{width: "100%", margin: "auto 0px 10px 0px"}}>
                              {/* <Alert
                                message="Alert message title"
                                description="Further details about the context of this alert."
                                type="info"
                              /> */}
                            </Spin>
                          }
                          
                        {
                          (urlInvoiceAdded && !customizePaySelected) &&
                          <img src={urlInvoiceAdded} className="product-loaded" style={{maxWidth: "100px"}} />
                        }
                      {
                        ((clientPayTypeSelected > 1 || (orderSelected && orderSelected.deliveryInformation && orderSelected.deliveryInformation.payType > 1)) && !customizePaySelected) &&
                          <Button type="primary" onClick={() => confirmPay()} disabled={!urlInvoiceAdded} danger>
                            CONFIRMAR PAGO
                        </Button>
                      }
                      {
                        (
                          (!customizePaySelected && (clientPayTypeSelected === 1) && !(orderSelected && !orderSelected.isDelivery && orderSelected.deliveryInformation && orderSelected.deliveryInformation.payType === 1 && orderSelected.state !== 3)) ||
                           (!customizePaySelected && orderSelected && orderSelected.isDelivery && orderSelected.deliveryInformation && orderSelected.deliveryInformation.payType === 1 && orderSelected.state !== 3)
                        ) &&
                        <Button type="primary" onClick={() => confirmPay()} disabled={(clientPayTypeSelected === 1 && !amountForPay) || (orderSelected.deliveryInformation && orderSelected.deliveryInformation.payType !== 1)} danger>
                            CONFIRMAR PAGO CON EFECTIVO 2
                        </Button>
                      }
                      </>
                      :
                      <>
                        {
                          (orderSelected && orderSelected.state === 4 && orderSelected.cancelOrderInformation) && <h5 className="text-red">ORDEN CANCELADA</h5>
                        }
                         <Button type="primary" onClick={() => downloadBill()} info>
                          VER RESUMEN DE COMANDA
                      </Button>
                      <br /> <br />
                      {
                        ((orderSelected && orderSelected.paymentAtHomeInformation && orderSelected.paymentAtHomeInformation.invoiceBillUrl) ||
                        (orderSelected && orderSelected.deliveryInformation && orderSelected.deliveryInformation.invoiceBillUrl)) &&
                        <a target="_blank" href={(orderSelected && orderSelected.paymentAtHomeInformation && orderSelected.paymentAtHomeInformation.invoiceBillUrl) ||
                       (orderSelected && orderSelected.deliveryInformation && orderSelected.deliveryInformation.invoiceBillUrl)
                        } download>
                          VER COMPROBANTE PAGO
                      </a>
                      }
                      {/* <h6>COMANDA YA SE ENCUENTRA PAGADA</h6> */}
                      </>
                  }
            </Card>
             </div>
       </Modal>
       <Modal visible={showModalCancelOrder} title="Cancelar de pedido" centered onCancel={() => closeModalCancelOrder()} okButtonProps={{hidden: true}} cancelText="CERRAR">
       <div>
              <h5>Selecciona un motivo: </h5>
                            <Select placeholder="Seleccionar" defaultValue={reasonCancelSelected} style={{ width: 200 }} onChange={(e) => handleReasonCancel(e)}>
                  {
                    REASONS_CANCEL_ORDER && REASONS_CANCEL_ORDER.map(e => {
                      return <Option value={e.cod}>{e.reason}</Option>
                    })
                  }
              </Select>
            </div>
            <br />
            <Button type="primary" onClick={() => confirmCancelOrder()} disabled={!reasonCancelSelected} danger>
                CANCELAR PEDIDO
            </Button>
       </Modal>
       <Modal visible={showModalReturnOrder} title="Devolución por un nuevo pedido" centered onCancel={() => closeModalReturnOrder()} okButtonProps={{hidden: true}} cancelText="CERRAR">
          {
            (orderSelected && !orderSelected.returnInformation) ?
            <>
            <div>
                  <small className="text-red">(*) La DEVOLUCION no incluye retorno de dinero, solo el cambio por un nuevo pedido.</small><br/>
                  <small className="text-red">(*) Si es necesario reembolsar dinero, comuníquese con el Administrador.</small>
                  <br /><br />

                              <h5>Selecciona un motivo: </h5>
                            <Select placeholder="Seleccionar" defaultValue={reasonReturnSelected} style={{ width: 200 }} onChange={(e) => handleReasonReturn(e)}>
                  {
                    REASONS_RETURN_ORDER && REASONS_RETURN_ORDER.map(e => {
                      return <Option value={e.cod}>{e.reason}</Option>
                    })
                  }
              </Select>
            </div>
            <br />
            <Button type="primary" onClick={() => confirmReturnOrder()} disabled={!reasonReturnSelected} danger>
                CONFIRMAR DEVOLUCION
            </Button>
            </>
            :
            <h5>Motivo de devolución: {orderSelected.returnInformation && orderSelected.returnInformation.reason && getReasonByCode(orderSelected.returnInformation.reason)}</h5>
          }

       </Modal>
       <Modal visible={showModalExpenses} title="GASTOS DEL DIA">
         <div className="row">
          <div className="col-6">

          </div>
          <div className="col-6">
            <label>INGRESE NUEVO GASTO</label>

            <h6>+ NUEVO GASTO </h6>
            <InputNumber value={null} />

            <Button type="primary" onClick={() => null} danger>
                GUARDAR GASTO
            </Button>
          </div>
         </div>
       </Modal>
       <Modal visible={showModalCashierInitial} title="CAJA CHICA DEL DIA">
       <div className="row">
          <div className="col-6">

          </div>
          <div className="col-6">
            <label>CAJA CHICA DEL DIA: S/ </label>

            <h6>ASIGNAR CAJA CHICA </h6>
            <InputNumber value={null} />

            <Button type="primary" onClick={() => null} danger>
                GUARDAR CAJA CHICA
            </Button>
          </div>
         </div>
       </Modal>
    </Fragment>
  );
};

export default OrderList;
