import React, { Fragment } from "react";

/// Components
import Markup from "./jsx";

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

import { withResizeDetector } from "react-resize-detector";

import firebase from 'firebase';
import config from './config';


firebase.initializeApp(config);
const publicationRef = firebase.database();
const publicationRefStorage = firebase.storage()

const allOrders =	publicationRef.ref('/');
const getAllOrdersRef =	publicationRef.ref('/orders');
const getAllOpenedsDays = publicationRef.ref('/warehouse');
const getAllDishes = publicationRef.ref('/dishesBD');
const getAllMotorcyclesRef = publicationRef.ref('/AllMotorcyclesBD');
const getConfigDisplayRef = publicationRef.ref('/ConfigDisplayBD');
const getAllTablesRef = publicationRef.ref('/tables')

const App = ({ width }) => {
   const body = document.querySelector("body");
   body.setAttribute("data-typography", "poppins");
   body.setAttribute("data-theme-version", "light");
   body.setAttribute("data-layout", "vertical");
   body.setAttribute("data-nav-headerbg", "color_1");
   body.setAttribute("data-headerbg", "color_1");
   body.setAttribute("data-sidebar-style", "full");
   body.setAttribute("data-sibebarbg", "color_1");
   body.setAttribute("data-primary", "color_1");
   body.setAttribute("data-sidebar-position", "fixed");
   body.setAttribute("data-header-position", "fixed");
   body.setAttribute("data-container", "wide");
   body.setAttribute("direction", "ltr");

   width > 767 && width < 1200
      ? body.setAttribute("data-sidebar-style", "mini")
      : width < 768
      ? body.setAttribute("data-sidebar-style", "overlay")
      : body.setAttribute("data-sidebar-style", "full");

   return (
      <Fragment>
         <Markup 
         allOrdersRef={allOrders}
          getAllOrdersRef={getAllOrdersRef}
           storage={publicationRefStorage} getAllMotorcyclesRef={getAllMotorcyclesRef} getAllOpenedsDays ={getAllOpenedsDays}
           getAllDishes={getAllDishes} getConfigDisplayRef={getConfigDisplayRef}
           getAllTablesRef={getAllTablesRef}
           />
      </Fragment>
   );
};

export default withResizeDetector(App);
