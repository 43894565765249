import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Space, Modal, InputNumber, Card, Avatar, Checkbox, List, Form, Input, notification, Alert } from 'antd';
import {PAY_TYPES, STATES_ORDER} from "../../../constants";
import { Table } from "react-bootstrap";
import TimeAgo from 'react-timeago'
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
const formatter = buildFormatter(spanishStrings)
const moment = require('moment-timezone');

const Notes = ({ toggleTab, PerfectScrollbar, toggleChatBox, openModal, deliveries }) => {
  const [showDetailActive, setShowDetailActive] = useState(false)
  const [showDetailActiveSelected, setShowDetailActiveSelected] = useState({})
  console.log(deliveries)
  const showDetail = (e) => {
    setShowDetailActive(true)
    setShowDetailActiveSelected(e)
  }
  const goBack = () => {
    setShowDetailActive(false)
    // setShowDetailActiveSelected({})
  }
  const getNameStateByCode = (state) => {
    let result = ""
    if (state) {
      STATES_ORDER.forEach(e => {
        if (e.value === state) result = e.label
      })
    }
    return result;   
  }
  const getNamePayTypeByCode = (state) => {
    let result = ""
    if (state) {
      PAY_TYPES.forEach(e => {
        if (e.value === state) result = e.label
      })
    }
    return result;   
  }
  const filterIsBetweenToday = (date) => {
    let result = false;
    const todayDate = moment.tz(new Date(), "America/Lima").format()
    // console.log(todayDate)
    let initDay = todayDate.split("T")
    let finalDay = todayDate.split("T")
    initDay = initDay[0]+"T00:00:00-05:00"
    finalDay = finalDay[0]+"T23:59:59-05:00"
    initDay = Date.parse(initDay)
    finalDay = Date.parse(finalDay)
    date = Date.parse(date)
    console.log(initDay)
    console.log(finalDay)
    console.log(date)
    // if (moment.tz(Date.parse(date).isAfter(Date.parse(initDay)))) result = true
    if (initDay <= date && date <= finalDay) result = true
    return result
  }
  return (
    <div
      className={`tab-pane fade ${toggleTab === 'pedidos delivery' ? 'active show' : ''}`}
      id='notes'
    >
      <div className='card mb-sm-3 mb-md-0 note_card'>
     {   
      !(showDetailActive && showDetailActiveSelected) && 
     <div className='card-header chat-list-header text-center'>
          <div>
            <h6 className='mb-1'>Buscar</h6>
          </div>
           <a onClick={() => openModal("add")}> <PlusCircleOutlined /></a>
           </div>}
        <PerfectScrollbar
          className={`card-body contacts_body p-0 dz-scroll  ${
            toggleChatBox ? 'ps ps--active-y' : ''
          }`}
          id='DZ_W_Contacts_Body2'
        >
          <br />
          {
          (showDetailActiveSelected && showDetailActiveSelected.products && showDetailActiveSelected.deliveryInformation && showDetailActive) &&
          <div className='d-flex bd-highlight col-12'>
              <div className='user_info col-12'>
                <span style={{maxWidth: "100%"}}>COMANDA #{showDetailActiveSelected.orderNumber}</span>
                <p style={{maxWidth: "100%"}}><b>DIRECCION:</b> {showDetailActiveSelected.deliveryInformation.address}</p>
                <p style={{maxWidth: "100%"}}><b>TELEFONO:</b> {showDetailActiveSelected.deliveryInformation.phone}</p>
                  {showDetailActiveSelected.deliveryInformation.motorycleAssigned && <p>
                    <b>MOTORIZADO:</b>
                    {showDetailActiveSelected.deliveryInformation.motorycleAssigned}</p>}
                <p style={{maxWidth: "100%"}}><b>ESTADO:</b> 
                {showDetailActiveSelected.deliveryInformation.state && getNameStateByCode(showDetailActiveSelected.deliveryInformation.state)}</p>
                <p style={{maxWidth: "100%"}}><b>HORA DE PEDIDO:</b> <TimeAgo date={showDetailActiveSelected.dateRegistered} formatter={formatter} /></p>
                <p><b>MEDIO DE PAGO: {(showDetailActiveSelected.deliveryInformation.payType && getNamePayTypeByCode(showDetailActiveSelected.deliveryInformation.payType)) || ""}</b></p>
                {showDetailActiveSelected.deliveryInformation.clientPayAmount && <p><b>Cliente paga con:</b> {showDetailActiveSelected.deliveryInformation.clientPayAmount}</p>}
                {showDetailActiveSelected.deliveryInformation.clientPayAmount && <p><b>Vuelto:</b> {showDetailActiveSelected.deliveryInformation.clientPayAmount-showDetailActiveSelected.totalAmount}</p>}
                <p style={{maxWidth: "100%"}}><b>TOTAL:</b> {showDetailActiveSelected.totalAmount}</p>
              <br/>
              <Table responsive className="w-100">
            <div id="example_wrapper" className="dataTables_wrapper">
              <table
                id="example delivery"
                className="display w-100 dataTable"
                role="grid"
                aria-describedby="example_info"
              >
                <thead>
                  <tr>
                  <td>CANT</td>
                  <td>ITEM</td>
                  <td>PRECIO</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    showDetailActiveSelected &&showDetailActiveSelected.products.map(e => {
                      return (
                        <tr>
                          <td>{e.quantity}</td>
                          <td>{e.description}</td>
                          <td>{e.amount}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </Table>
          <br />
                { (showDetailActive && showDetailActiveSelected) &&
                  <Button type="button" className="col-12" style={{textAlign: "center", backgroundColor: "#2f4cdd"}} onClick={() => goBack()}>
                      <p style={{margin: "auto", color:"white"}}>IR ATRAS</p>
                  </Button>}
                  <br />
                  <br />
                  { (showDetailActive && showDetailActiveSelected) &&
                  <Button type="button" className="col-12" style={{textAlign: "center", backgroundColor: "#2f4cdd"}} onClick={() => navigator.clipboard.writeText(showDetailActiveSelected.urlToClient || "")}>
                      <p style={{margin: "auto", color:"white"}}>COPIAR LINK DE TRACKING</p>
                  </Button>}
              </div>
            </div>
          }
          <ul className='contacts'>
            {
              ((deliveries && deliveries.length > 0) && !showDetailActive) && deliveries.map(e => {
                if (e && e.isDelivery && e.deliveryInformation && e.deliveryInformation.address && e.products && filterIsBetweenToday(e.dateRegistered)) {
                  return (
                    <li className='active'>
                      <div className='d-flex bd-highlight'>
                        <div className='user_info'>
                          <span>COMANDA #{e.orderNumber}</span>
                          <p><b>DIRECCION:</b> {e.deliveryInformation.address}</p>
                          <p><b>TELEFONO:</b> {e.deliveryInformation.phone}</p>
                          <p><b>MOTORIZADO:</b> {e.deliveryInformation.motorycleAssigned}</p>
                          {
                            (e.deliveryInformation && e.deliveryInformation.state) ?
                            <p><b>ESTADO:</b>{getNameStateByCode(e.deliveryInformation.state)}</p> :
                            <p><b>ESTADO:</b> {e.state && getNameStateByCode(e.state)}</p>
                          }
                        </div>
                        <div className='ml-auto'>
                          <a onClick={() => showDetail(e)} style={{color: "white"}} className='btn btn-primary btn-xs sharp mr-1'>
                            <i className='fa fa-eye'></i>
                          </a>
                          {/* <Link to='#' className='btn btn-danger btn-xs sharp'>
                            <i className='fa fa-trash'></i>
                          </Link> */}
                        </div>
                      </div>
                    </li>
                  )
                }
              })
            }
          </ul>
        </PerfectScrollbar>
      </div>
    </div>
  )
}

export default Notes
