import React, { Fragment, useState } from "react";
import PageTitle from "../../../../layouts/PageTitle";
import { Dropdown } from "react-bootstrap";
import { Tag, Modal } from 'antd';

/// images
import avartar5 from "../../../../../images/avatar/5.png";
import avartar1 from "../../../../../images/avatar/1.png";
import { Link } from "react-router-dom";
import {STATES_ORDER, PAY_TYPES, USERS} from "../../../../../constants";

const Customers = ({getAllMotorcyclesRef, getAllOrdersRef}) => {
  const [allMotorcyclesBD, setAllMotorcyclesBD] = useState([])
  const [motorcycleSelected, setMotorcycleSelected] = useState({})
  const [openModalListOrders, setOpenModalListOrders] = useState(false)
  const [allOrdersBD, setAllOrdersBD] = useState([])

  const openAllOrdersByMoto = (e) => {
    console.log(e)
    setMotorcycleSelected(e)
    setOpenModalListOrders(true)
  }
  const closeModalListOrders= () => {
    setOpenModalListOrders(false)
  }
  const drop = (e) => {    
    return <Dropdown>
      <Dropdown.Toggle variant="" className="table-dropdown i-false">
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
        </svg>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {/* <Dropdown.Item href="#">Edit</Dropdown.Item> */}

        <Dropdown.Item className="text-danger" onClick={() => openAllOrdersByMoto(e)}>
          VER PEDIDOS
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  }

  const chackbox = document.querySelectorAll(".customer_shop_single input");
  const motherChackBox = document.querySelector(".customer_shop input");
  // console.log(document.querySelectorAll(".publish_review input")[0].checked);
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  const chack = (i) => (
    <div className={`custom-control custom-checkbox ml-2`}>
      <input
        type="checkbox"
        className="custom-control-input "
        id={`checkAll${i}`}
        required=""
        onClick={() => chackboxFun()}
      />
      <label className="custom-control-label" htmlFor={`checkAll${i}`}></label>
    </div>
  );
  const getAllMotorcyclesFirebase = () => {
    let allIds = []
    let allMotorcyclesAux = []
    getAllMotorcyclesRef.on("value", (snapshot) => {
      let result = []
         if (snapshot.val() !== null) {
          snapshot.forEach(e => {
            allIds.push(e.key)
          })
           allMotorcyclesAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
           allMotorcyclesAux = Object.values(allMotorcyclesAux)
           allMotorcyclesAux = allMotorcyclesAux.map((e, index) => {
              console.log(allIds)
             if (allIds.length) {
             allIds.forEach(k => {
               e.keyBD = `MOTORCYCLEID${allIds[index]}`
             })
             }
             return e
          })
          // result = filterOrders(allMotorcyclesAux, myWorkerDelivery)
          console.log(allMotorcyclesAux)
          setAllMotorcyclesBD(allMotorcyclesAux)
          // setOrdersLoaded(true)
          //  console.log(result, "allOrdersAux")
         }
         return;
       }, (error) => {
         console.log("ERROR: " + error.code);
       });
    }
    if (allMotorcyclesBD && allMotorcyclesBD.length === 0) getAllMotorcyclesFirebase()
    const getAllOrdersFirebase = () => {
      let allIds = []
      let allOrdersAux = []
      getAllOrdersRef.on("value", (snapshot) => {
           if (snapshot.val() !== null) {
            snapshot.forEach(e => {
              allIds.push(e.key)
            })
             allOrdersAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
             allOrdersAux = Object.values(allOrdersAux)
             allOrdersAux = allOrdersAux.map((e, index) => {
                console.log(allIds)
               if (allIds.length) {
               allIds.forEach(k => {
                 e.keyBD = `ORDERID${allIds[index]}`
               })
               }
               return e
            })
            setAllOrdersBD(allOrdersAux)
             console.log(allOrdersAux, "allOrdersAux")
           }
           return;
         }, (error) => {
           console.log("ERROR: " + error.code);
         });
      }
      if (allOrdersBD && allOrdersBD.length === 0) getAllOrdersFirebase()
      
  const getNameStateByCode = (state) => {
    let result = ""
    if (state) {
      STATES_ORDER.forEach(e => {
        if (e.value === state) result = e.label
      })
    }
    return result;   
  }
  return (
    <Fragment>
      <PageTitle activeMenu="Motorizados" motherMenu="Total" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table mb-0 table-striped">
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Nombres</th>
                      <th>Telefono</th>
                      <th className="pl-5 width200">DNI</th>
                      <th>Fecha de inicio</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody id="customers">
                    {
                      allMotorcyclesBD && allMotorcyclesBD.map(e => {
                        return (
                          <tr className="btn-reveal-trigger">
                          <td className="py-2">
                            <a>{e.code || ""}</a>
                          </td>
                          {/* <td className="customer_shop_single">{chack(1)}</td> */}
                          <td className="py-3">
                            <Link to="/ecom-customers">
                              <div className="media d-flex align-items-center">
                                <div className="avatar avatar-xl mr-2">
                                  <div className="">
                                    <img
                                      className="rounded-circle img-fluid"
                                      src={avartar5}
                                      width="30"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="media-body">
                                  <h5 className="mb-0 fs--1">{e.name || ""}</h5>
                                </div>
                              </div>
                            </Link>
                          </td>
                          <td className="py-2">
                            {" "}
                            <a href="tel:2012001851">(201) 200-1851</a>
                          </td>
                          <td className="py-2 pl-5 wspace-no">
                            2392 Ma
                          </td>
                          <td className="py-2">30/03/2018</td>
                          <td className="py-2 text-right">{drop(e)}</td>
                        </tr>
                        )
                      })
                    }
                  
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal visible={openModalListOrders} title="VER PEDIDOS ASIGNADOS" style={{ top: 20 }} onCancel={() => closeModalListOrders()} okButtonProps={{hidden: true}} cancelText="CERRAR">
                  <table className="table items-table">
                        <tbody>
                          <tr>
                            <th className="my-0 text-black font-w300 fs-20">
                              PEDIDOS DE HOY
                            </th>
                          </tr>
                          {
                            (allOrdersBD && allOrdersBD.length > 0) ? 
                            allOrdersBD.map(e => {
                              if (e && motorcycleSelected && e.deliveryInformation && e.deliveryInformation.motorycleAssigned && e.deliveryInformation.motorycleAssigned === motorcycleSelected.code)
                              return (
                                <tr style={{cursor: "pointer"}} onClick={() => null}>
                                <td>
                                  <div className="media">
                                    <a>
                                      <img
                                        className="mr-3 to=-fluid rounded"
                                        width={85}
                                      />
                                    </a>
                                    <div className="media-body">
                                      <small className="mt-0 mb-1 font-w500">
                                        <a>
                                          COMANDA # {e.orderNumber}
                                        </a><br />
                                        <Tag color="#f50">{(e && e.deliveryInformation) && getNameStateByCode(e.deliveryInformation.state).toUpperCase()}</Tag>
                                      </small>
                                      <h5 className="">
                                          DIRECCION: {e.deliveryInformation && e.deliveryInformation.address}
                                      </h5>
                                      <h5 className="">
                                          TELEFONO: {e.deliveryInformation && e.deliveryInformation.phone}
                                      </h5>
                                      <div className="star-review fs-14">
                                        {/* <i className="fa fa-star text-orange" />
                                        <i className="fa fa-star text-orange" />
                                        <i className="fa fa-star text-orange" />
                                        <i className="fa fa-star text-gray" />
                                        <i className="fa fa-star text-gray" /> */}
                                        <span className="ml-3 text-dark">
                                          TOTAL: {e.totalAmount} soles
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                {/* <td>
                                  <h4 className="my-0 text-secondary font-w600">
                                    3x
                                  </h4>
                                </td>
                                <td>
                                  <h4 className="my-0 text-secondary font-w600">
                                    $14.99
                                  </h4>
                                </td>
                                <td>
                                  <h4 className="my-0 text-secondary font-w600">
                                    $44.97
                                  </h4>
                                </td>
                                <td>
                                  <Link
                                    to="/order"
                                    className="ti-close fs-28 text-danger las la-times-circle"
                                  />
                                </td> */}
                          </tr>
                              )
                            })
                            :
                            <tr>
                              <td>
                                SIN PEDIDOS ASIGNADOS HASTA EL MOMENTO
                              </td>
                            </tr>
                          }
                        </tbody>
                      </table>
      </Modal>
    </Fragment>
  );
};

export default Customers;
