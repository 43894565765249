import React, { useState, useRef } from "react";
import { Table, Button, Space, Modal, InputNumber, Card, Avatar, Checkbox, List, Form, Input, notification, Alert, Select } from 'antd';

const SpinnerLoader = ({isVisible, handleCancel}) => {
  return (
    <div>
      <Modal closable={false} visible={isVisible} onOk={handleCancel} 
        centered className="modal-spinner" cancelButtonProps={{hidden: true}} okButtonProps={{hidden: true}} footer={false}
        >
          <div className="content-spinner">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          <h3 style={{color: "white"}}><b>CARGANDO...</b></h3>
          </div>
         </Modal>
    </div>
  )
}

export default SpinnerLoader;
