import { GENERAL_CATEGORIES } from "../constants";

export class Utils {
  static getNameCategory(code) {
    let result = ""

    switch(code) {
      case 1: return result = "PARRILLAS"
      case 2: return result = "COMBOS"
      case 3: return result = "BEBIDAS"
      case 4: return result = "CREMAS"
      case 5: return result = "GUARNICIONES"
      case 6: return result = "INFUSIONES"
    }
    return result;
  }
  
  static getNameStateByCode(code) {
    let result = ""

    switch(code) {
      case 1: return result = "Borrador"
      case 2: return result = "Delivery Pagado"
      case 3: return result = "Pagado"
      case 4: return result = "Cancelado"
    }
    return result;
  }
}